import React, { useState, useEffect, useRef } from "react";

import { Text, View, ActivityIndicator } from "react-native";
import { useNavigate, useLocation } from "react-router-dom";

import { SnackbarProvider, enqueueSnackbar } from "notistack";

import PageLayout from "examples/LayoutContainers/PageLayout";

function Authentication() {

    // //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const search = useLocation().search;

    const uid = new URLSearchParams(search).get("uid");
    const ref = new URLSearchParams(search).get("ref");
    const action = new URLSearchParams(search).get("act");

    useEffect(() => {

        const list_obj = {
            "uid": uid,
            "ref": ref,
            "act": action
        }

        fetch(process.env.REACT_APP_REQ_IP + "/authentication/unverified", { method: "POST", mode: "cors" ,headers: {"Content-Type": "application/json"}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);
            
            if(data.result) {
                enqueueSnackbar(data.message, { 
                    variant: "success",
                    persist: false
                  });
            }
            else {
                enqueueSnackbar(data.message, { 
                    variant: "error",
                    persist: false
                });
            }

            
        })
        .catch(err => {
            setError(err);
        });

        navigate("/authentication/sign-in", { replace: true });

    }, [uid, ref, action]);


    if(error) {
        return (
            <PageLayout>
                <View style={{ flex: 1, margin:"50px", justifyContent: "center", alignItems: "center" }}>
                    <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: "top", horizontal: "center" }} />
                    <Text style={{ fontSize: 18, color:"white"}}> {"Hata"}: {error}</Text>
                </View>
            </PageLayout>
        );
    }
    else if(isLoading) {
        return (
            <PageLayout>
                <View style={{ flex: 1, margin:"50px", justifyContent: "center", alignItems: "center" }}>
                    <ActivityIndicator size="large" color="#40ab9a" />
                </View>
            </PageLayout>
        );
    }
    else {
        return (
            <PageLayout>
                <article style={{ padding: "100px" }}>
                    <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: "top", horizontal: "center" }} />
                    <h1 className="text-center">Yönlendiriliyorsunuz... 🔄</h1>
                </article>
            </PageLayout>
        );
    }

}

export default Authentication;