// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import coinIcon from "assets/images/icons/smart_coin.png";

function IlanCards({ title="", description="", konum="", coin="" }) {

  IlanCards.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    konum: PropTypes.string,
    coin: PropTypes.number
  };

  return (
    <Card sx={{ height: "100%" }} className="ilan-width-2">
      <MDBox padding="1rem">
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="title" className="ilan-card-title" textTransform="capitalize">
            {title}
          </MDTypography>
          <Divider />
          <MDTypography className="ilan-card-desc" textTransform="capitalize" component="div" variant="button" color="warning" fontWeight="light">
            {description}
          </MDTypography>
          <MDTypography className="ilan-card-desc" component="div" variant="button" style={{color: "#f28706"}} fontWeight="light">
          <img className="smart-small-alt" alt="smart_coin" src={coinIcon} /> ₺{coin}
          </MDTypography>
          <MDBox className="ilan-card-location" display="flex" alignItems="center">
            <MDTypography variant="button" color="light" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>location_on</Icon>
            </MDTypography>
            <MDTypography variant="button" color="light" fontWeight="bold">
              {konum}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default IlanCards;
