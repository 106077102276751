import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import { Text, View, ActivityIndicator } from 'react-native';

import { Model, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./index.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";


function AdayTestCevaplari() {

  const {state} = useLocation();
  const { ilanid, adayUid, testTitle } = state; 

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [userSurvey, setUserSurvey] = useState([]);
  const [testData, setTestData] = useState(null);

  const atoken = window.sessionStorage.getItem("access_token");

  useEffect(() => {
    var list_obj = {
      'adayUid': adayUid,
      'ilanID': ilanid,
      'testTitle': testTitle
    }

    setIsLoading(true);

    fetch(process.env.REACT_APP_REQ_IP + '/get-user-test-preview', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      setIsLoading(false);

      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });

        setError(data.message);
      }
      else {
        setUserSurvey(data.testInfo)
        setTestData(data.testData)
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [adayUid]);

  var survey;

  if(userSurvey) {
    survey = new Model(userSurvey);
    survey.mode = "display";

    survey.data = testData;

    const correctStr = "Doğru";
    const incorrectStr = "Yanlış";
    
    // Builds an HTML string to display in a question title
    function getTextHtml (text, str, isCorrect) {
      if (text.indexOf(str) < 0)
          return undefined;
  
      return text.substring(0, text.indexOf(str)) +
          "<span class='" +  (isCorrect ? "correctAnswer" : "incorrectAnswer" ) + "'>" +
              str +
          "</span>";
    }

    function changeTitle (q) {
      if (!q) return;
  
      const isCorrect = q.isAnswerCorrect();
      if (!q.prevTitle) {
          q.prevTitle = q.title;
      }
      if (isCorrect === undefined) {
          q.title = q.prevTitle;
      }
      q.title =  q.prevTitle + ' ' + (isCorrect ? correctStr : incorrectStr);
    }

    survey.onTextMarkdown.add((_, options) => {
      const text = options.text;
      let html = getTextHtml(text, correctStr, true);
      if (!html) {
          html = getTextHtml(text, incorrectStr, false);
      }
      if (!!html) {
          // Set an HTML string with the "Correct" or "Incorrect" suffix for display
          options.html = html;
      }
    });

    survey.getAllQuestions().forEach(question => changeTitle(question));

  };


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#40ab9a" />
        </View>
    );
  }

  else if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"#022559"}}>Hata: {error}</Text>
          </View>
      );
  }

  else {
    return (
      <DashboardLayout>
          <DashboardNavbar/>
          <MDBox mt={12}>
            <Grid container spacing={6} pt={0}>
              <Grid item xs={12} pt={0}>
                  <Survey model={survey} />
              </Grid>
            </Grid>
          </MDBox>
      </DashboardLayout>
    );
  }

}

export default AdayTestCevaplari;
