// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.correctAnswer {
    color: green;
}
.incorrectAnswer {
    color: red;
}
.sd-element__title.sd-element__title--disabled {
    opacity: 1;
}
.sd-timer--top {
    margin-top: 4em !important;
}

.sd-root-modern.sd-root-modern--full-container {
    /* background-color: #123274; */
    background-color: transparent;
    /* border-radius: 2em; */
}

.sd-body.sd-body--static {
    padding-bottom: 1.7em;
}`, "",{"version":3,"sources":["webpack://./src/layouts/test/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,0BAA0B;AAC9B;;AAEA;IACI,+BAA+B;IAC/B,6BAA6B;IAC7B,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".correctAnswer {\n    color: green;\n}\n.incorrectAnswer {\n    color: red;\n}\n.sd-element__title.sd-element__title--disabled {\n    opacity: 1;\n}\n.sd-timer--top {\n    margin-top: 4em !important;\n}\n\n.sd-root-modern.sd-root-modern--full-container {\n    /* background-color: #123274; */\n    background-color: transparent;\n    /* border-radius: 2em; */\n}\n\n.sd-body.sd-body--static {\n    padding-bottom: 1.7em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
