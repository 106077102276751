import { useState } from "react";
import { Text, View, ActivityIndicator } from 'react-native';
import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";

import { enqueueSnackbar } from 'notistack';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import CoverLayout from "layouts/authentication/components/CoverLayout";

import bgImage from "assets/images/calisan.jpg";

function AdminSignIn() {

  let navigate = useNavigate();

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let uid = window.sessionStorage.getItem("uid");
  let userType = window.sessionStorage.getItem("userType");

  //if user authenticated
  if(uid !== null && userType == "aday") {
    navigate("/aday-anasayfasi", { replace: true });
  }
  if(uid !== null && userType == "isveren") {
    navigate("/isveren-anasayfasi", { replace: true });
  }  

  //if user authenticated
  if(uid !== null && userType == "admin") {
    navigate("/admin", { replace: true });
  } 


  const handleSignIn = (e) => {

    setIsLoading(true);

    const email = e.target.email.value;
    const password = e.target.password.value;

    const list_obj = {
      "email": email,
      "password": password
    }

    fetch(process.env.REACT_APP_REQ_IP + '/admin-login', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      setIsLoading(false);
      
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
      }
      else {
        window.sessionStorage.setItem("uid", data.userInfo[0][0]);
        window.sessionStorage.setItem("firmid", data.userInfo[0][1]);
        window.sessionStorage.setItem("nameSurname", data.userInfo[0][2]);
        window.sessionStorage.setItem("email", data.userInfo[0][3]);
        window.sessionStorage.setItem("userType", data.userInfo[0][4]);
        window.sessionStorage.setItem("access_token", data.access_token);

        if(data.userInfo[0][4] == "admin") {
          navigate("/authentication/admin-sign-in", { replace: true });
        }  
        else {
          enqueueSnackbar("Lütfen kullanıcı girişini kullanınız.", { 
            variant: 'error',
            persist: false
          });
        }
      }
    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });
    
  }


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#40ab9a" />
        </View>
    );
  }

  else if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
          </View>
      );
  }

  else {
    return (
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center">
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Admin Girişi
            </MDTypography>
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={2}>
                <MDTypography component={MuiLink} href="https://www.instagram.com/smartmatchik/" variant="body1" color="white">
                  <Instagram color="inherit" />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                  <MDTypography component={MuiLink} href="https://www.linkedin.com/company/smartmatchinsankaynaklari/" variant="body1" color="white">
                  <LinkedIn color="inherit" />
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSignIn}>
              <MDBox mb={2}>
                <MDInput type="email" label="Email" id="email" fullWidth />
              </MDBox>
  
              <MDBox mb={2}>
                <MDInput type="password" label="Şifre" id="password" fullWidth />
              </MDBox>
  
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  component={Link}
                  to="/authentication/reset-password"
                  variant="button"
                  fontWeight="regular"
                  color="dark"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                  &nbsp;&nbsp;Şifremi Unuttum
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                  Giriş Yap
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    );

  }
}

export default AdminSignIn;
