import { useState, useEffect } from "react";

import { enqueueSnackbar } from 'notistack';
import { saveAs } from 'file-saver';

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Icon from "@mui/material/Icon";

import PropTypes from 'prop-types';

export default function basvurularData() {

  const [error, setError] = useState(null);

  const [basvurularSum, setBasvurularSum] = useState();
  const [testList, setTestList] = useState([]);

  const [asamaValues, setAsamaValues] = useState();
  const [testAsamaValue, setTestAsamaValue] = useState();

  const ilanid = window.sessionStorage.getItem("ilanid");
  const atoken = window.sessionStorage.getItem("access_token");

  const saveManual = (adayUid, basvuruID) => {
    saveAs(
      process.env.REACT_APP_PYTHON_REDIRECT_IP + "/files/" + ilanid + '/' + adayUid + '_' + String(basvuruID) + '.pdf'
    );
  };


  const handleAsamaChanged = (e, adayUid, basvuruID) => {

    const list_obj = {
      "uid": adayUid,
      "ilanID": ilanid,
      "asama": e,
      "basvuruID": basvuruID
    }

    fetch(process.env.REACT_APP_REQ_IP + '/update-aday-asama', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'warning',
          persist: false
        });
      }
      else {
        setAsamaValues([e, basvuruID]);

        enqueueSnackbar(data.message, { 
          variant: 'success',
          persist: false
        });
      }
    })
    .catch(err => {
        setError(err);
    });

  }


  const handleTestAsamaChanged = (e, adayUid, basvuruID) => {

    const list_obj = {
      "uid": adayUid,
      "ilanID": ilanid,
      "asama": e,
      "basvuruID": basvuruID
    }

    fetch(process.env.REACT_APP_REQ_IP + '/update-test-secimi', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'warning',
          persist: false
        });
      }
      else {
        setTestAsamaValue([e, basvuruID]);

        enqueueSnackbar(data.message, { 
          variant: 'success',
          persist: false
        });
      }
    })
    .catch(err => {
        setError(err);
    });

  }

  const Pozisyon = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  Pozisyon.propTypes = {
    name: PropTypes.string
  };

  const Asama = ({asamaCurr, adayUid, basvuruID}) => (
    <MDBox className="asama-select-width">
      <Select
        id="asamaCurr"
        defaultValue={asamaCurr}
        value={asamaCurr}
        className="asama-container"
        onChange={(e) => handleAsamaChanged(e.target.value, adayUid, basvuruID)}>
        <MenuItem value="Kısa Liste">Kısa liste</MenuItem>
        <MenuItem value="Uzun Liste">Uzun liste</MenuItem>
        <MenuItem value="Test">Test</MenuItem>
        <MenuItem value="Olumlu">Olumlu</MenuItem>
        <MenuItem value="Olumsuz">Olumsuz</MenuItem>
        <MenuItem value="Görüşme takvimi oluşturuldu">Görüşme takvimi oluşturuldu</MenuItem>
      </Select>
    </MDBox>
  );

  Asama.propTypes = {
    asamaCurr: PropTypes.string,
    adayUid: PropTypes.string,
    basvuruID: PropTypes.number
  };


  const AsamaTest = ({asamaCurr, adayUid, basvuruID}) => (
    <MDBox className="asama-select-width">
      <Select
        id="asamaTestCurr"
        defaultValue={asamaCurr}
        value={asamaCurr}
        className="asama-container"
        onChange={(e) => handleTestAsamaChanged(e.target.value, adayUid, basvuruID)}>
        <MenuItem selected value="Seçiniz">Seçiniz</MenuItem>
        
        {testList.map(((test, index) => (
          <MenuItem key={index} value={test}>{test}</MenuItem>
        )))}

      </Select>
    </MDBox>
  );

  AsamaTest.propTypes = {
    asamaCurr: PropTypes.string,
    adayUid: PropTypes.string,
    basvuruID: PropTypes.number
  };


  useEffect(() => {

    const list_obj = {
      "ilanID": ilanid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/basvurular', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
              
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
        setError(data.message);
      }
      else {
        setBasvurularSum(data['basvurularInfo']);

      }
    })
    .catch(err => {
        setError(err);
    });


    fetch(process.env.REACT_APP_REQ_IP + '/get-test-titles', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }}).then(res => res.json()).then(data => {
              
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
        setError(data.message);
      }
      else {
        setTestList(data['test_list']);

      }
    })
    .catch(err => {
        setError(err);
    });

  }, [ilanid, asamaValues, testAsamaValue]);

  if(error) {
    return {columns: [
      { Header: "Aday İsmi", accessor: "adayİsmi", width: "25%", align: "left" },
      { Header: "Öneren Kişinin İsmi", accessor: "onerenİsmi", width: "15%", align: "left" },
      { Header: "Aday Aşaması", accessor: "adayAsamasi", align: "center" },
      { Header: "Aday CV'si", accessor: "adayCV", align: "center" },
      { Header: "IK Testleri", accessor: "ikTest", align: "center" },
    ], rows: [{}]}
  }
  else {
    return {
      columns: [
        { Header: "Aday İsmi", accessor: "adayİsmi", width: "20%", align: "left", enableColumnFilter: true },
        { Header: "Öneren Kişinin İsmi", accessor: "onerenİsmi", width: "15%", align: "left", enableColumnFilter: true },
        { Header: "Aday Aşaması", accessor: "adayAsamasi", width: "25%", align: "center", enableColumnFilter: false },
        { Header: "Aday CV'si", accessor: "adayCV", align: "center", enableColumnFilter: false },
        { Header: "IK Testleri", accessor: "ikTest", width: "20%", align: "center", enableColumnFilter: true },
      ],
      rows: 
          basvurularSum?.map((basvuru) => (
            {
              adayİsmi: <Pozisyon name={basvuru[2]} />,
              onerenİsmi: <Pozisyon name={basvuru[8]} />,
              adayAsamasi: <Asama basvuruID={basvuru[0]} asamaCurr={basvuru[1]} adayUid={basvuru[3]} />,
              adayCV: (
                <MDButton className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => saveManual(basvuru[3], basvuru[0])}>
                  <Icon sx={{fontWeight: "bold"}}>download</Icon>
                  &nbsp;CV indir
                </MDButton> 
              ),
              ikTest: <AsamaTest asamaCurr={basvuru[7]} basvuruID={basvuru[0]} adayUid={basvuru[3]} />
            }  
          ))
    };

  }

}