import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { enqueueSnackbar } from 'notistack';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Button from '@mui/material/Button';
import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

export default function kullanicilarTableData() {

  const [error, setError] = useState(null);
  const [userList, setUserList] = useState();
  const [updateProcess, setUpdateProcess] = useState();

  const uid = window.sessionStorage.getItem("uid");

  const Pozisyon = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  Pozisyon.propTypes = {
    name: PropTypes.string
  };

  const atoken = window.sessionStorage.getItem("access_token");

  useEffect(() => {

    const list_obj = {
      "uid": uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/user_list', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
              
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
        setError(data.message);
      }
      else {
        setUserList(JSON.parse(data['users']));
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid, updateProcess]);


function handleDeactivate(userUid) {
    const list_obj = {
      "uid": uid,    
      "userUid": userUid,
      "auth": "deactivate"
  }

    fetch(process.env.REACT_APP_REQ_IP + '/update_authentication', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`    
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
        
        if(data.result === true) {
            enqueueSnackbar(data.message, { 
                variant: 'success',
                persist: false
            });
        }
        else {
            enqueueSnackbar(data.message, { 
                variant: 'error',
                persist: false
            });
        }

        setUpdateProcess("deactivate");
    })
    .catch(err => {
        setError(err);
    });
}

function handleActivate(userUid) {
  const list_obj = {
      "uid": uid,    
      "userUid": userUid,
      "auth": "activate"
  }

  fetch(process.env.REACT_APP_REQ_IP + '/update_authentication', { method: 'POST', mode: 'cors' ,headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${atoken}`
  }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      if(data.result === true) {
          enqueueSnackbar(data.message, { 
              variant: 'success',
              persist: false
          });
      }
      else {
          enqueueSnackbar(data.message, { 
              variant: 'error',
              persist: false
          });
      }

      setUpdateProcess("activate");
  })
  .catch(err => {
      setError(err);
  });
}

  if(error) {
    return {columns: [
      { Header: "Ad / Soyad", accessor: "nameSurname", width: "25%", align: "left" },
      { Header: "Meslek", accessor: "jobTitle", align: "left" },
      { Header: "Smartcoin", accessor: "smartcoin", align: "left" },
      { Header: "Şirket İsmi", accessor: "compName", align: "left" },
      { Header: "Aktifleştir/Pasifleştir", accessor: "activateUser", align: "center" },
    ], rows: [{}]}
  }
  else {
    return {
      columns: [
        { Header: "Ad / Soyad", accessor: "nameSurname", width: "25%", align: "left" },
        { Header: "Meslek", accessor: "jobTitle", align: "left" },
        { Header: "Smartcoin", accessor: "smartcoin", align: "left" },
        { Header: "Şirket İsmi", accessor: "compName", align: "left" },
        { Header: "Aktifleştir/Pasifleştir", accessor: "activateUser", align: "center" },
      ],
      rows: 
          userList?.map((user) => {

            if(user.authStatus ==="verified") {
              return ({
                nameSurname: <Pozisyon name={user.nameSurname} />,
                jobTitle: <Pozisyon name={user.jobTitle} />,
                smartcoin: <Pozisyon name={String(user.smartcoin)} />,
                compName: <Pozisyon name={user.compName} />,
                activateUser: (
                  <MDButton key={user.uid} className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => handleDeactivate(user.uid)}>
                    <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
                    &nbsp;Pasifleştir
                  </MDButton> 
                ),
              })
            }
            else {
              return ({
                nameSurname: <Pozisyon name={user.nameSurname} />,
                jobTitle: <Pozisyon name={user.jobTitle} />,
                smartcoin: <Pozisyon name={String(user.smartcoin)} />,
                compName: <Pozisyon name={user.compName} />,
                activateUser: (
                  <MDButton key={user.uid} className="queInputDelete" type="button" variant="gradient" color="success" onClick={() => handleActivate(user.uid)}>
                    <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
                    &nbsp;Aktifleştir
                  </MDButton> 
                ),
              })
            }

          })
    };

  }

}              