import { useState, useEffect } from "react";
import { Text, View, ActivityIndicator } from 'react-native';

import PropTypes from "prop-types";

import { enqueueSnackbar } from 'notistack';

import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ProfileInfoCard({ title, shadow }) {

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [userInfo, setUserInfo] = useState(null);

  const userType = window.sessionStorage.getItem("userType");
  const uid = window.sessionStorage.getItem("uid");
  const email = window.sessionStorage.getItem("email");

  function formatDate(iDate) {
    var inputDate = new Date(iDate);
    var formattedDate = inputDate.getFullYear()+'-'+(inputDate.getMonth() + 1)+'-'+ 
    inputDate.getDate();
    return formattedDate;
  }

  useEffect(() => {

    const list_obj = {
      "email": email
    }

    setIsLoading(true)

    fetch(process.env.REACT_APP_REQ_IP + '/check-user', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      setIsLoading(false);

      //kullanıcı yoksa
      if(data.result === true) {
        setUserInfo(data.userInfo);

        setNameSurname(data.userInfo[0][2]);
        setJobTitle(data.userInfo[0][6]);
        setCompName(data.userInfo[0][8]);
        setCompCategory(data.userInfo[0][9]);
        setCompMainAddr(data.userInfo[0][11]);
        setCompDate(formatDate(data.userInfo[0][10]));
        setCompBranchAddr(data.userInfo[0][12]);

        setCompTaxNumber(data.userInfo[0][13]);
        setCompTaxAddr(data.userInfo[0][14]);
        setCompInvoiceAddr(data.userInfo[0][15]);

      }
  
    })
    .catch(err => {
        setError(err);
    });

  }, [uid]);

  const [updatedNameSurname, setNameSurname] = useState(null);
  const [updatedJobTitle, setJobTitle] = useState(null);

  const [updatedCompName, setCompName] = useState(null);
  const [updatedCompCategory, setCompCategory] = useState(null);
  const [updatedCompDate, setCompDate] = useState(null);
  const [updatedCompMainAddr, setCompMainAddr] = useState(null);
  const [updatedCompBranchAddr, setCompBranchAddr] = useState(null);

  const [updatedCompTaxNumber, setCompTaxNumber] = useState(null);
  const [updatedCompTaxAddr, setCompTaxAddr] = useState(null);
  const [updatedCompInvoiceAddr, setCompInvoiceAddr] = useState(null);

  const [textWarning, setWarning] = useState(false);

  function handleUpdateUserInfo() {

    setIsLoading(true);

    const list_obj = {
      "uid": uid,
      "nameSurname": updatedNameSurname,
      "jobTitle": updatedJobTitle,
      "compName": updatedCompName,
      "compCategory": updatedCompCategory,
      "compDate": updatedCompDate,
      "compMainAddr": updatedCompMainAddr,
      "compBranchAddr": updatedCompBranchAddr,
      "compTaxNumber": updatedCompTaxNumber,
      "compTaxAddr": updatedCompTaxAddr,
      "compInvoiceAddr": updatedCompInvoiceAddr
    }

    const atoken = window.sessionStorage.getItem("access_token");

    fetch(process.env.REACT_APP_REQ_IP + '/update_profile', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      setIsLoading(false);

      if(data.result === true) {
        enqueueSnackbar(data.message, { 
          variant: 'success',
          persist: false
        });
      }
      else {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
      }
  
    })
    .catch(err => {
        setError(err);
    });

  }

  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#40ab9a" />
        </View>
    );
  }

  else if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
          </View>
      );
  }
  else {
    return (
      <Card className="profile-container" sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>

          <MDButton variant="gradient" color="dark" type="button" onClick={handleUpdateUserInfo}>
            Güncelle
          </MDButton>
        </MDBox>
        <MDBox p={2}>

        {textWarning &&
          <MDTypography variant="h6" className="warningText" fontWeight="medium" textTransform="capitalize">
              Zorunlu alanlar boş bırakılamaz.
          </MDTypography>
        }
  
        {userInfo && userType == "aday" &&
        <>
          <div className="profile-double-items">
            <MDBox key="fullName" display="flex" py={1} pr={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
                İsim - Soyisim : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedNameSurname} fullWidth id="fullName" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setNameSurname(e.target.value); setWarning(false);}}}/>
            </MDBox>

            <MDBox key="jobTitle" display="flex" py={1} pl={1} className="profile-items full-width-item">
              <MDTypography pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
                Meslek : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedJobTitle} fullWidth id="jobTitle" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setJobTitle(e.target.value); setWarning(false);}}}/>
            </MDBox>
          </div>

  
          <MDBox key="emailAddress" display="flex" py={1} className="profile-items">
            <MDTypography pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              Email : &nbsp;
              &nbsp;
            </MDTypography>
            <MDInput type="text" variant="outlined" value={userInfo[0][3]} fullWidth id="emailAddress" disabled/>
          </MDBox>
        </>
        }

        {userInfo && userType == "isveren" &&

        <>
          <div className="profile-double-items">
            <MDBox key="fullName" display="flex" py={1} pr={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
                İsim - Soyisim : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedNameSurname} fullWidth id="fullName" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setNameSurname(e.target.value); setWarning(false);}}}/>
            </MDBox>

            <MDBox key="emailAddress" display="flex" py={1} pl={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
                Email : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={userInfo[0][3]} fullWidth id="emailAddress" disabled/>
            </MDBox>
          </div>

          <MDTypography md={6} mt={3} mb={2} variant="h6" fontWeight="bold" textTransform="capitalize">
            Firma Bilgileri &nbsp;
          </MDTypography>

          <div className="profile-double-items">
            <MDBox key="compName" display="flex" py={1} pr={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              Firma İsmi : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedCompName} fullWidth id="compName" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setCompName(e.target.value); setWarning(false);}}}/>
            </MDBox>

            <MDBox key="compCategory" display="flex" py={1} pl={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
                Ana faaliyet alanı : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedCompCategory} fullWidth id="compCategory" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setCompCategory(e.target.value); setWarning(false);}}}/>
            </MDBox>
          </div>

          <MDBox key="compMain" display="flex" py={1} className="profile-items">
            <MDTypography pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
            Firma merkez adresi : &nbsp;
              &nbsp;
            </MDTypography>
            <MDInput type="text" variant="outlined" value={updatedCompMainAddr} fullWidth id="compMain" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setCompMainAddr(e.target.value); setWarning(false);}}}/>
          </MDBox>

          <div className="profile-double-items">
            <MDBox key="compDate" display="flex" py={1} pr={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              Kuruluş tarihi : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="date" variant="outlined" value={updatedCompDate} fullWidth id="compDate" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setCompDate(e.target.value); setWarning(false);}}}/>
            </MDBox>

            <MDBox key="compBranch" display="flex" py={1} pl={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              Firma şube adresi : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedCompBranchAddr} fullWidth id="compBranch" onChange={(e) => setCompBranchAddr(e.target.value)}/>
            </MDBox>
          </div>

          <MDTypography md={6} mt={3} mb={2} variant="h6" fontWeight="bold" textTransform="capitalize">
            Fatura Bilgileri &nbsp;
          </MDTypography>

          <div className="profile-double-items">
            <MDBox key="vkn" display="flex" py={1} pr={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              VKN : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedCompTaxNumber} fullWidth id="vkn" onChange={(e) => setCompTaxNumber(e.target.value)}/>
            </MDBox>

            <MDBox key="vknAddr" display="flex" py={1} pl={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              Vergi Dairesi adresi : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedCompTaxAddr} fullWidth id="vknAddr" onChange={(e) => setCompTaxAddr(e.target.value)}/>
            </MDBox>
          </div>

          <MDBox key="compInvAddr" display="flex" py={1} className="profile-items">
            <MDTypography pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
            Fatura adresi : &nbsp;
              &nbsp;
            </MDTypography>
            <MDInput type="text" variant="outlined" value={updatedCompInvoiceAddr} fullWidth id="compInvAddr" onChange={(e) => setCompInvoiceAddr(e.target.value)}/>
          </MDBox>


        </>
        } 
          

        {userInfo && userType == "admin" &&

        <>
          <div className="profile-double-items">
            <MDBox key="fullName" display="flex" py={1} pr={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
                İsim - Soyisim : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedNameSurname} fullWidth id="fullName" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setNameSurname(e.target.value); setWarning(false);}}}/>
            </MDBox>

            <MDBox key="emailAddress" display="flex" py={1} pl={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
                Email : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={userInfo[0][3]} fullWidth id="emailAddress" disabled/>
            </MDBox>
          </div>

          <MDTypography md={6} mt={3} mb={2} variant="h6" fontWeight="bold" textTransform="capitalize">
            Firma Bilgileri &nbsp;
          </MDTypography>

          <div className="profile-double-items">
            <MDBox key="compName" display="flex" py={1} pr={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              Firma İsmi : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedCompName} fullWidth id="compName" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setCompName(e.target.value); setWarning(false);}}}/>
            </MDBox>

            <MDBox key="compCategory" display="flex" py={1} pl={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
                Ana faaliyet alanı : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedCompCategory} fullWidth id="compCategory" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setCompCategory(e.target.value); setWarning(false);}}}/>
            </MDBox>
          </div>

          <MDBox key="compMain" display="flex" py={1} className="profile-items">
            <MDTypography pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
            Firma merkez adresi : &nbsp;
              &nbsp;
            </MDTypography>
            <MDInput type="text" variant="outlined" value={updatedCompMainAddr} fullWidth id="compMain" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setCompMainAddr(e.target.value); setWarning(false);}}}/>
          </MDBox>

          <div className="profile-double-items">
            <MDBox key="compDate" display="flex" py={1} pr={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              Kuruluş tarihi : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="date" variant="outlined" value={updatedCompDate} fullWidth id="compDate" required onChange={(e) => {if(e.target.value === null || e.target.value === "") {setWarning(true)} else {setCompDate(e.target.value); setWarning(false);}}}/>
            </MDBox>

            <MDBox key="compBranch" display="flex" py={1} pl={1} className="profile-items full-width-item">
              <MDTypography md={6} pb={1} variant="button" fontWeight="bold" textTransform="capitalize">
              Firma şube adresi : &nbsp;
                &nbsp;
              </MDTypography>
              <MDInput type="text" variant="outlined" value={updatedCompBranchAddr} fullWidth id="compBranch" onChange={(e) => setCompBranchAddr(e.target.value)}/>
            </MDBox>
          </div>
        </>
        } 
  
        </MDBox>
      </Card>
    );
  }

}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
