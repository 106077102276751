import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { enqueueSnackbar } from 'notistack';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Button from '@mui/material/Button';
import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

export default function ilanlarimTableData() {

  let navigate = useNavigate();

  const [error, setError] = useState(null);

  const [ilanlarimSum, setİlanlarimSum] = useState();

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  const handleBasvurular = (ilanID) => {
    window.sessionStorage.setItem("ilanid", ilanID);
    navigate('/basvurular', { state: { ilanid: ilanID }, replace: true });
  };

  const Duzenle = ({ilanID}) => (
    <MDButton className="queInputDelete" type="button" variant="gradient" color="dark" href={'/ilani-duzenle?id=' + String(ilanID)}>
      <Icon sx={{fontWeight: "bold"}}>edit</Icon>
      &nbsp;Düzenle
    </MDButton> 
  );

  const Pozisyon = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const DuzenleTXT = ({name}) => (
    <MDBox display="flex" alignItems="center" justifyContent="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  Pozisyon.propTypes = {
    name: PropTypes.string
  };

  DuzenleTXT.propTypes = {
    name: PropTypes.string
  };

  Duzenle.propTypes = {
    ilanID: PropTypes.string
  };


  useEffect(() => {

    const list_obj = {
      "uid": uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/ilanlarim', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
              
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
        setError(data.message);
      }
      else {
        setİlanlarimSum(data['ilanlarimInfo']);
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid]);

  if(error) {
    return {columns: [
      { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", width: "25%", align: "left" },
      { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
      { Header: "Başvurular", accessor: "basvurular", align: "center" },
      { Header: "Düzenle", accessor: "duzenle", align: "center" },
    ], rows: [{}]}
  }
  else {
    return {
      columns: [
        { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", width: "25%", align: "left" },
        { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
        { Header: "Başvurular", accessor: "basvurular", align: "center" },
        { Header: "Düzenle", accessor: "duzenle", align: "center" },
      ],
      rows: 
          ilanlarimSum?.map((ilan) => {

            if(ilan[3] ==="yayinda") {
              return ({
                pozisyonUnvani: <Pozisyon name={ilan[1]} />,
                sirketIsmi: <Pozisyon name={ilan[2]} />,
                basvurular: (
                  <MDButton key={ilan[0]} className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => handleBasvurular(ilan[0])}>
                    <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
                    &nbsp;Başvuruları Görüntüle
                  </MDButton> 
                ),
                duzenle: <DuzenleTXT name="İlanınız onaylanmıştır." />,
              })
            }
            else {
              return ({
                pozisyonUnvani: <Pozisyon name={ilan[1]} />,
                sirketIsmi: <Pozisyon name={ilan[2]} />,
                basvurular: (
                  <MDButton key={ilan[0]} className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => handleBasvurular(ilan[0])}>
                    <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
                    &nbsp;Başvuruları Görüntüle
                  </MDButton> 
                ),
                duzenle: <Duzenle ilanID={ilan[0]} />
              })
            }

          })
    };

  }

}              