import { View, ActivityIndicator } from 'react-native';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import basvurularimTableData from "layouts/basvurularim/data/basvurularimTableData";

function Basvurularim() {
  const { columns, rows } = basvurularimTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />

        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  coloredShadow="info">
                  <MDTypography variant="h6" color="white">
                    Başvurularım 
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  {columns && rows &&
                      <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  }
                  {!columns || !rows &&
                    <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                        <ActivityIndicator size="large" color="#40ab9a" />
                    </View>
                  }
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>


      <Footer />
    </DashboardLayout>
  );
}

export default Basvurularim;
