import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import { Text, View, ActivityIndicator } from 'react-native';

import { Model, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./index.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";

Serializer.addProperty("question", {
    name: "score:number"
});

function SurveyComponent() {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [userSurvey, setUserSurvey] = useState([]);
  const [queCategory, setQueCategory] = useState(null);


  const {state} = useLocation();
  const { ilanid } = state; 

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  useEffect(() => {
    var list_obj = {
      'uid': uid,
      'ilanid': ilanid
    }

    setIsLoading(true);

    fetch(process.env.REACT_APP_REQ_IP + '/get-user-test', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      setIsLoading(false);

      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });

        setError(data.message);
      }
      else {
        setUserSurvey(data.testInfo)
        setQueCategory(data.testType)
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid]);

  var survey;

  function calculateMaxScore(questions) {
      var maxScore = 0;
      questions.forEach((question) => {
        if (!!question.score) {
          maxScore += question.score;
        }
      });
      return maxScore;
  }

  function calculateTotalScore(data) {
      var totalScore = 0;
      Object.keys(data).forEach((qName) => {
        const question = survey.getQuestionByValueName(qName);
        if (question.isAnswerCorrect()) {
          if (!!question.score) {
            totalScore += question.score;
          }
        }
      });
      return totalScore;
  }

  if(userSurvey) {
    survey = new Model(userSurvey);
    survey.onComplete.add((sender, options) => {

        const totalScore = calculateTotalScore(sender.data);
        const maxScore = calculateMaxScore(sender.getAllQuestions());

        // Save the scores in survey results
        sender.setValue("maxScore", maxScore);
        sender.setValue("totalScore", totalScore);

        var list_obj2 = {
          'uid': uid,
          'ilanid': ilanid,
          'queCategory': queCategory,
          'answersArr': survey.getPlainData(),
          'maxScore': maxScore,
          'totalScore': totalScore

        }


        fetch(process.env.REACT_APP_REQ_IP + '/submit-user-test', { method: 'POST', mode: 'cors' ,headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${atoken}`
        }, body: JSON.stringify(list_obj2)}).then(res => res.json()).then(data => {
      
          setIsLoading(false);
          
          if(data.result === false) {
            setError(data.message)
          }

        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });

    });

  }


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#40ab9a" />
        </View>
    );
  }

  else if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"#022559"}}>Hata: {error}</Text>
          </View>
      );
  }

  else {
    return (
      <DashboardLayout>
          <DashboardNavbar/>
          <MDBox mt={12}>
            <Grid container spacing={6} pt={0}>
              <Grid item xs={12} pt={0}>
                  <Survey model={survey} />
              </Grid>
            </Grid>
          </MDBox>
      </DashboardLayout>
    );
  }


}

export default SurveyComponent;
