import React from 'react';
import { ReactComponent as SuccessIcon } from "assets/images/small-logos/success.svg";

import PageLayout from "examples/LayoutContainers/PageLayout";

function Success() {

    return (
        <PageLayout>
            <article style={{ padding: "100px" }}>
                <h1 className='text-center'>Ödeme Başarılı 🎉</h1>
                <h3 className="text-center">Ödemeniz başarı ile alınmıştır ve paketiniz yükseltilmiştir.</h3>
                <div className="card-body">
                    <SuccessIcon className="payment-icon" />
                </div>
            </article>
        </PageLayout>
    );

}

export default Success;