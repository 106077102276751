// Material Dashboard 2 React layouts
import DashboardAday from "layouts/dashboardAday";
import Basvur from "layouts/basvur-oner";
import Basvurularim from "layouts/basvurularim";
import Onerilerim from "layouts/onerilerim";

// @mui icons
import Icon from "@mui/material/Icon";

const routesAday = [
  {
    type: "collapse",
    name: "Anasayfa",
    key: "aday-anasayfasi",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/aday-anasayfasi",
    component: <DashboardAday />,
  },
  {
    type: "collapse",
    name: "Başvur / Öner",
    key: "basvur-oner",
    icon: <Icon fontSize="small">touch_app_icon</Icon>,
    route: "/basvur-oner",
    component: <Basvur />,
  },
  {
    type: "collapse",
    name: "Başvurularım",
    key: "basvurularim",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/basvurularim",
    component: <Basvurularim />,
  },
  {
    type: "collapse",
    name: "Önerilerim",
    key: "onerilerim",
    icon: <Icon fontSize="small">people_alt</Icon>,
    route: "/onerilerim",
    component: <Onerilerim />,
  },
  {
    type: "collapse",
    name: "İletişim",
    key: "more",
    icon: <Icon fontSize="small">more_horiz</Icon>,
    route: "https://smart-match.net/bize-ulasin/",
  }
];

export default routesAday;
