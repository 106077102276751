import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Text, View, ActivityIndicator } from 'react-native';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from "@mui/material/Checkbox";

import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { enqueueSnackbar } from 'notistack';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import gelisimselData from 'layouts/ilan-ver/data/gelisimselData';
import illerData from './data/illerData';

const animatedComponents = makeAnimated();

function IlaniDuzenleDash() {

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ilanID = params.get('id');

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [pozisyonValue, setPozisyonValue] = useState("Uzman");
  const [deneyimValue, setDeneyimValue] = useState("Deneyimli");
  const [dilSeviyesiValue, setDilSeviyesiValue] = useState("Başlangıç");
  const [egitimDurumuValue, setEgitimDurumuValue] = useState("Üniversite (Mezun)");
  const [bilgSevValue, setBilgSevValue] = useState("ortaSev");
  const [bilgSevValueDB, setBilgSevValueDB] = useState("ortaSev");
  const [gelisimselValue, setGelisimselSelect] = useState(['Problem çözme becerileri', 'İletişim becerileri']);
  const [calisilacakIl, setCalisilacakIl] = useState('Ankara');
  const [calismaSekli, setCalismaSekli] = useState("Sürekli / Tam Zamanlı");
  const [calismaModeliValue, setCalismaModeli] = useState("Hibrit");

  const [privValue, setPrivValue] = useState();

  const gelisimselYetkin = gelisimselData.map(x => ({value: x, label: x}));
  const calisilacakIller = illerData.map(x => ({value: x, label: x}));

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  const [ilans, setIlans] = useState();

  const [questions, setQuestions] = useState([]);
  const [queExist, setQueExist] = useState(false);

  useEffect(() => {

    const list_obj = {
      "ilanID": ilanID,
      "uid": uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/ilans-detay', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {

      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
      }
      else {
        const ilans = JSON.parse(data['ilans']);
        setIlans(ilans[0]);

        if(ilans[0].isNamePrivate == 1) {
          setPrivValue(true)
        }
        else {
          setPrivValue(false)
        }
      }
    })
    .catch(err => {
        console.log(err);
    });


    if(!ilanID) {
      var list_obj2 = {
        'ilanID': window.sessionStorage.getItem("ilanid")
      }
    }
    else {
      var list_obj2 = {
        'ilanID': ilanID
      }
    }

    fetch(process.env.REACT_APP_REQ_IP + '/get-spec-que', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj2)}).then(res => res.json()).then(data => {
      
      setIsLoading(false);
      
      if(data.result === false) {
        setQueExist(false)
      }
      else {
        setQueExist(true)
        const tmp_formatted_questions = []
        data.specQue.map((element) => {
          const tmp_row = {
            type: element[2],
            question: element[3],
            options: [element[4], element[5], element[6], element[7]]
          }

          tmp_formatted_questions.push(tmp_row);
        })

        setQuestions(tmp_formatted_questions)
      }
    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });

  }, [ilanID, uid, isLoading]);


  const handleAddQuestion = (type) => {
    setQuestions([...questions, { type, question: '', options: [] }]);
  };

  const handleQuestionChange = (index, key, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][key] = value;
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (index, optionIndex, option) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options[optionIndex] = option;
    setQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handlePozisyonChange = (e) => {
    setPozisyonValue(e.target.value);
  }

  const handleDeneyimChange = (e) => {
    setDeneyimValue(e.target.value);
  }

  const handleDilSeviyesiChange = (e) => {
    setDilSeviyesiValue(e.target.value)
  }

  const handleEgitimDurumuChange = (e) => {
    setEgitimDurumuValue(e.target.value);
  }

  const handleBilgSevisyesiChange = (e) => {

    const seviyeDict = {
      "temel": "Temel Bilgisayar Kullanımı (Office Programları, Email, Tarayıcı kullanım Yetkinliği)",
      "ortaSev": "Orta Seviye (Veritabanı yönetimi, İşletim sistemlerini etkin kullanabilme)",
      "gelismis": "Gelişmiş Bilgisayar Becerisi (Programlama bilgisi ve Ağ yönetimi)",
      "uzmanDuzey": "Uzman Düzey (Büyük veri analizi, Sunucu yönetimi)",
      "bilgMuh": "Bilgisayar Mühendisliği veya ilgili lisans derecesi"
    }

    setBilgSevValue(e.target.value);
    setBilgSevValueDB(seviyeDict[e.target.value]);
  }

  const handleGelisimselYetkin = (e) => {
    setGelisimselSelect(Array.isArray(e) ? e.map(x => x.value) : []);
  }

  const handleIlSecimi = (e) => {
    setCalisilacakIl(e.value);    
  }

  const handleCalismaSekli = (e) => {
    setCalismaSekli(e.target.value);
  }

  const handleCalismaModeliChange = (e) => {
    setCalismaModeli(e.target.value);
  }

  const handleIsNamePrivateChange = (e) => {
    setPrivValue(e.target.checked);
  }

  const handleUpdateIlan = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const list_obj = {
      "uid": uid,
      "ilanID": ilanID,
      "sirketIsmi": e.target.sirketIsmi.value,
      "pozisyonUnvani": e.target.pozisyonUnvani.value,
      "bolum": e.target.bolum.value,
      "pozisyon": pozisyonValue,
      "deneyim": deneyimValue,
      "isTanimi": e.target.isTanimi.value,
      "dilSeviyesi": dilSeviyesiValue,
      "egitimDurumu": egitimDurumuValue,
      "bilgSev": bilgSevValueDB,
      "teknikYetk": e.target.teknikYetk.value,
      "gelisimsel": gelisimselValue,
      "calisilacakIl": calisilacakIl,
      "ilceSecimi": e.target.ilceSecimi.value,
      "digerAdres": e.target.digerAdres.value,
      "calismaSekli": calismaSekli,
      "calismaModeli": calismaModeliValue,
      "calismaGun": e.target.calismaGun.value,
      "kazanilacakUcret": e.target.kazanilacakUcret.value,
      "mutlakaSahip": e.target.mutlakaSahip.value,
      "questions": questions,
      "isNamePrivate": privValue
    }

    fetch(process.env.REACT_APP_REQ_IP + '/update-ilan', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      setIsLoading(false);
      
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'warning',
          persist: false
        });
      }
      else {
        enqueueSnackbar(data.message, { 
          variant: 'success',
          persist: false
        });
        
      }
    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });

  }


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#40ab9a" />
        </View>
    );
  }

  else if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
          </View>
      );
  }

  else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
            {ilans && 
              <Card>
                <MDBox component="form" role="form" onSubmit={handleUpdateIlan}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="warning"
                    borderRadius="lg"
                    className="ilanFormHeader"
                    coloredShadow="warning">
                    <MDTypography variant="h6" color="white">
                      İlanı Düzenle
                    </MDTypography>
    
                    <MDButton variant="gradient" color="dark" type="submit">
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;İlanı Kaydet
                    </MDButton>
                  </MDBox>

                    <div className='form-container form-container-top'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="sirketIsmi">Şirket <sup>*</sup></InputLabel>
                        <MDInput className="ilanFormInput" type="text" id="sirketIsmi" variant="outlined" defaultValue={ilans.sirketIsmi} required/> 
                      </MDBox>
        
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="pozisyonUnvani">Pozisyon Unvanı <sup>*</sup></InputLabel>
                        <MDInput className="ilanFormInput" type="text" id="pozisyonUnvani" variant="outlined" defaultValue={ilans.pozisyonUnvani} required/>
                        {/* defaultValue={ilans[0].pozisyonUnvani} */}
                      </MDBox>
                    </div>

                    <div className='form-container'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="bolum">Bölüm <sup>*</sup></InputLabel>
                        <MDInput className="ilanFormInput" type="text" id="bolum" variant="outlined" defaultValue={ilans.bolum} required/>
                      </MDBox>
                    </div>

                    <div className='form-container'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="pozisyon">Organizasyondaki Yeri <sup>*</sup></InputLabel>
                        <Select
                          id="pozisyon"
                          defaultValue={ilans.pozisyon}
                          value={pozisyonValue}
                          className="ilanFormSelect"
                          onChange={handlePozisyonChange} required>
                          <MenuItem value="Üst Düzey Yönetici">Üst Düzey Yönetici</MenuItem>
                          <MenuItem value="Orta Düzey Yönetici">Orta Düzey Yönetici</MenuItem>
                          <MenuItem value="Yönetici Adayı">Yönetici Adayı</MenuItem>
                          <MenuItem value="Uzman">Uzman</MenuItem>
                          <MenuItem value="Yeni Başlayan">Yeni Başlayan</MenuItem>
                          <MenuItem value="Personel">Personel</MenuItem>
                          <MenuItem value="Eleman">Eleman</MenuItem>
                          <MenuItem value="Serbest / Freelancer">Serbest / Freelancer</MenuItem>
                          <MenuItem value="İşçi / Mavi Yaka">İşçi / Mavi Yaka</MenuItem>
                          <MenuItem value="Stajyer">Stajyer</MenuItem>
                        </Select>
                      </MDBox>
    
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="deneyim">Deneyim <sup>*</sup></InputLabel>
                        <Select
                          id="deneyim"
                          defaultValue={ilans.deneyim}
                          value={deneyimValue}
                          className="ilanFormSelect"
                          onChange={handleDeneyimChange} required>
                          <MenuItem value="Deneyimli">Deneyimli</MenuItem>
                          <MenuItem value="Deneyimsiz">Deneyimsiz</MenuItem>
                        </Select>
                      </MDBox>
                    </div>
  
                    <div className='form-container'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="isTanimi">İş Tanımı <sup>*</sup></InputLabel>
                        <MDInput className="ilanFormInput" type="text" id="isTanimi" rows={4} defaultValue={ilans.isTanimi} variant="outlined" multiline required/>
                      </MDBox>
                    </div>

                    <div className='form-container'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="dilSeviyesi">Yabancı Dil Bilgisi Seviyesi <sup>*</sup></InputLabel>
                        <Select
                          id="dilSeviyesi"
                          defaultValue={ilans.dilSeviyesi}
                          value={dilSeviyesiValue}
                          className="ilanFormSelect"
                          onChange={handleDilSeviyesiChange} required>
                          <MenuItem value="Başlangıç">Başlangıç</MenuItem>
                          <MenuItem value="Orta">Orta</MenuItem>
                          <MenuItem value="İleri">İleri</MenuItem>
                          <MenuItem value="Ana Dil">Ana Dil</MenuItem>
                          <MenuItem value="Çok dilli (Poliglot)">Çok dilli (Poliglot)</MenuItem>
                        </Select>
                      </MDBox>
    
    
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="egitimDurumu">Eğitim Durumu <sup>*</sup></InputLabel>
                        <Select
                          id="egitimDurumu"
                          defaultValue={ilans.egitimDurumu}
                          value={egitimDurumuValue}
                          className="ilanFormSelect"
                          onChange={handleEgitimDurumuChange} required>
                          <MenuItem value="Doktora (Mezun)">Doktora (Mezun)</MenuItem>
                          <MenuItem value="Doktora (Öğrenci)">Doktora (Öğrenci)</MenuItem>
                          <MenuItem value="Yüksek Lisans (Mezun)">Yüksek Lisans (Mezun)</MenuItem>
                          <MenuItem value="Yüksek Lisans (Öğrenci)">Yüksek Lisans (Öğrenci)</MenuItem>
                          <MenuItem value="Üniversite (Mezun)">Üniversite (Mezun)</MenuItem>
                          <MenuItem value="Üniversite (Öğrenci)">Üniversite (Öğrenci)</MenuItem>
                          <MenuItem value="Meslek Yüksekokulu (Mezun)">Meslek Yüksekokulu (Mezun)</MenuItem>
                          <MenuItem value="Meslek Yüksekokulu (Öğrenci)">Meslek Yüksekokulu (Öğrenci)</MenuItem>
                          <MenuItem value="Lise (Mezun)">Lise (Mezun)</MenuItem>
                          <MenuItem value="Lise (Öğrenci)">Lise (Öğrenci)</MenuItem>
                          <MenuItem value="İlköğretim (Mezun)">İlköğretim (Mezun)</MenuItem>
                          <MenuItem value="İlköğretim (Öğrenci)">İlköğretim (Öğrenci)</MenuItem>
                        </Select>
                      </MDBox>
                    </div>
  
                    <div className='form-container'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="bilgSev">Bilgisayar Bilgisi Seviyesi <sup>*</sup></InputLabel>
                        <Select
                          id="bilgSev"
                          defaultValue={ilans.bilgSev}
                          value={bilgSevValue}
                          className="ilanFormSelectFull"
                          onChange={handleBilgSevisyesiChange} required>
                          <MenuItem value="temel">Temel Bilgisayar Kullanımı (Office Programları, Email kullanma yetkinliği)</MenuItem>
                          <MenuItem value="ortaSev">Orta Seviye (Veritabanı yönetimi, İşletim sistemlerini etkin kullanabilme)</MenuItem>
                          <MenuItem value="gelismis">Gelişmiş Bilgisayar Becerisi (Programlama bilgisi ve Ağ yönetimi)</MenuItem>
                          <MenuItem value="uzmanDuzey">Uzman Düzey (Büyük veri analizi, Sunucu yönetimi)</MenuItem>
                          <MenuItem value="bilgMuh">Bilgisayar Mühendisliği veya ilgili lisans derecesi</MenuItem>
                        </Select>
                      </MDBox>

                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="gelisimselYetkin">Gelişimsel Yetkinlikler <sup>*</sup></InputLabel>
                        <CreatableSelect 
                          id="gelisimselYetkin"
                          className="ilanFormInput special-select" 
                          options={gelisimselYetkin} 
                          closeMenuOnSelect={false}
                          required
                          components={animatedComponents}
                          defaultValue={{value: ilans.gelisimsel, label: ilans.gelisimsel}}
                          onChange={handleGelisimselYetkin}
                          isMulti
                        />
                      </MDBox>
                    </div>

                    <div className='form-container'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel" htmlFor="teknikYetk">Diğer Teknik Yetkinlikler <sup>*</sup></InputLabel>
                        <MDInput className="ilanFormInput" type="text" id="teknikYetk" rows={4} defaultValue={ilans.teknikYetk} variant="outlined" multiline required/>
                      </MDBox>
                    </div>

                  <div className='form-container'>
                    <MDBox className="ilanFormInputBox">
                      <InputLabel className="ilanFormInputLabel" htmlFor="ilSecimi">Çalışılacak İl <sup>*</sup></InputLabel>
                      <ReactSelect 
                        id="ilSecimi"
                        className="ilanFormInput special-select" 
                        options={calisilacakIller} 
                        defaultValue={{value: ilans.calisilacakIl, label: ilans.calisilacakIl}}
                        components={animatedComponents}
                        onChange={handleIlSecimi}
                        required
                      />
                    </MDBox>
  
                    <MDBox className="ilanFormInputBox">
                      <InputLabel className="ilanFormInputLabel" htmlFor="ilceSecimi">Çalışılacak İlçe <sup>*</sup></InputLabel>
                      <MDInput className="ilanFormSelect" type="text" id="ilceSecimi" variant="outlined" defaultValue={ilans.ilceSecimi} required/>
                    </MDBox>
  
                    <MDBox className="ilanFormInputBox">
                      <InputLabel className="ilanFormInputLabel" htmlFor="digerAdres">Diğer Adres (Sokak, Cadde vb.) (Opsiyonel)</InputLabel>
                      <MDInput className="ilanFormSelect" type="text" id="digerAdres" variant="outlined" defaultValue={ilans.digerAdres}/>
                    </MDBox>
                  </div>

                  <div className='form-container'>
                    <MDBox className="ilanFormInputBox">
                      <InputLabel className="ilanFormInputLabel" htmlFor="calismaSekli">Çalışma Şekli <sup>*</sup></InputLabel>
                      <Select
                        id="calismaSekli"
                        value={calismaSekli}
                        defaultValue={ilans.calismaSekli}
                        className="ilanFormSelectFull"
                        onChange={handleCalismaSekli} required>
                        <MenuItem value="Sürekli / Tam Zamanlı">Sürekli / Tam Zamanlı</MenuItem>
                        <MenuItem value="Dönemsel / Proje Bazlı">Dönemsel / Proje Bazlı</MenuItem>
                        <MenuItem value="Stajyer">Stajyer</MenuItem>
                        <MenuItem value="Yarı Zamanlı / Part Time">Yarı Zamanlı / Part Time</MenuItem>
                        <MenuItem value="Serbest Zamanlı">Serbest Zamanlı</MenuItem>
                      </Select>
                    </MDBox>
  
                    <MDBox className="ilanFormInputBox">
                      <InputLabel className="ilanFormInputLabel" htmlFor="calismaGun">Çalışma gün ve saatleri <sup>*</sup></InputLabel>
                      <MDInput className="ilanFormInput" type="text" id="calismaGun" variant="outlined" defaultValue={ilans.calismaGun} required/>
                    </MDBox>
                  </div>

                  <div className='form-container'>
                    <MDBox className="ilanFormInputBox">
                      <InputLabel className="ilanFormInputLabel" htmlFor="kazanilacakUcret">Önerilen Adayın İşe Yerleşmesi Durumunda Kazanılacak Ücret (₺) <sup>*</sup></InputLabel>
                      <MDInput className="ilanFormInput" type="number" id="kazanilacakUcret" variant="outlined" defaultValue={ilans.kazanilacakUcret} required/>
                    </MDBox>

                    <MDBox className="ilanFormInputBox">
                      <InputLabel className="ilanFormInputLabel" htmlFor="calismaModeli">Çalışma Modeli <sup>*</sup></InputLabel>
                      <Select
                        id="calismaModeli"
                        defaultValue={ilans.calismaModeli}
                        value={calismaModeliValue}
                        className="ilanFormSelect"
                        onChange={handleCalismaModeliChange} required>
                        <MenuItem value="Ofisten">Ofisten</MenuItem>
                        <MenuItem value="Hibrit">Hibrit</MenuItem>
                        <MenuItem value="HomeOffice">HomeOffice</MenuItem>
                      </Select>
                    </MDBox>
                  </div>

                  <div className='form-container'>
                    <MDBox className="ilanFormInputBox" display="flex" alignItems="center" mt={1}>

                      <Checkbox onChange={handleIsNamePrivateChange} checked={privValue} />

                      <InputLabel className="ilanFormPrivateLabel privateCheckbox" htmlFor="isNamePrivate">Firma ismi gizli kalsın</InputLabel>
                    </MDBox>
                  </div>
                  
                  <div className='form-container'>
                    <MDBox className="ilanFormInputBox">
                      <InputLabel className="ilanFormInputLabel" htmlFor="mutlakaSahip">Adayın mutlaka sahip olması gereken 3 kriter <sup>*</sup></InputLabel>
                      <MDInput className="ilanFormInput" type="text" id="mutlakaSahip" rows={3} variant="outlined" multiline defaultValue={ilans.mutlakaSahip} required/>
                    </MDBox>
                  </div>

                  {!queExist &&

                    <div className='form-container'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel queInputLabel" >Adaya sorulacak sorular?</InputLabel>
                        {questions.length <=4 && 
                        <MDButton type="button" variant="gradient" color="secondary" onClick={() => handleAddQuestion('text')}>
                          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                          &nbsp;Yeni soru ekle
                        </MDButton>
                      }
  
                      {questions.length > 4 && 
                        <MDButton type="button" variant="gradient" color="secondary" disabled>
                          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                          &nbsp;Daha fazla soru ekleyemezsiniz
                        </MDButton>
                      }
  
                      {questions.map((question, index) => (
                        <MDBox key={index}>
                          <MDBox className="ilanFormQueBox">
                            <div style={{width:"50%"}}>
                            <InputLabel className="ilanFormInputLabel" htmlFor={"queType" + String(index)}>Soru tipini seçiniz</InputLabel>
                            <Select
                              id={"queType" + String(index)}
                              value={question.type}
                              className="ilanFormSelect"
                              onChange={(e) => handleQuestionChange(index, 'type', e.target.value)} required>
                              <MenuItem value="text">Açık Uçlu</MenuItem>
                              <MenuItem value="multipleChoice">Çoktan Seçmeli</MenuItem>
                            </Select>
                            </div>
  
                            <MDButton className="queInputDelete que-delete-btn" type="button" variant="gradient" color="secondary" onClick={() => handleRemoveQuestion(index)}>
                                <Icon sx={{ fontWeight: "bold" }}>delete_outline</Icon>
                                &nbsp;Sil
                            </MDButton> 
  
                          </MDBox>
  
                          {question.type === 'text' ? (
                            <MDBox>
                              <MDInput className="ilanFormInput" type="text" placeholder="Soruyu yazınız.." value={question.question} onChange={(e) => handleQuestionChange(index, 'question', e.target.value)} variant="outlined" required/>
                            </MDBox>
                          ) : (
                            <MDBox>
                              <MDInput className="ilanFormInput" type="text" placeholder="Soruyu yazınız.." value={question.question} onChange={(e) => handleQuestionChange(index, 'question', e.target.value)} variant="outlined" required/>
                              {/* {[1, 2, 3, 4].map((optionIndex) => (
                                <MDBox className="ilanFormQueBoxCoktan" key={optionIndex}>
                                  <MDInput className="ilanFormInput" type="text"
                                  placeholder={`Seçenek ${optionIndex}`} value={question.options[index] || ''} 
                                  onChange={(e) => handleAddOption(index, e.target.value)} variant="outlined" required/>
                                </MDBox>
                              ))} */}
  
                              <MDBox className="ilanFormQueBoxCoktan" key="1">
                                <MDInput className="ilanFormInput" type="text"
                                placeholder={`Seçenek 1`} value={questions[index].options[0]} 
                                onChange={(e) => handleAddOption(index, 0, e.target.value)} variant="outlined" required/>
                              </MDBox>
  
  
                              <MDBox className="ilanFormQueBoxCoktan" key="2">
                                <MDInput className="ilanFormInput" type="text"
                                placeholder={`Seçenek 2`} value={questions[index].options[1]} 
                                onChange={(e) => handleAddOption(index, 1, e.target.value)} variant="outlined" required/>
                              </MDBox>
  
  
                              <MDBox className="ilanFormQueBoxCoktan" key="3">
                                <MDInput className="ilanFormInput" type="text"
                                placeholder={`Seçenek 3`} value={questions[index].options[2]} 
                                onChange={(e) => handleAddOption(index, 2, e.target.value)} variant="outlined"/>
                              </MDBox>
  
  
                              <MDBox className="ilanFormQueBoxCoktan" key="4">
                                <MDInput className="ilanFormInput" type="text"
                                placeholder={`Seçenek 4`} value={questions[index].options[3]} 
                                onChange={(e) => handleAddOption(index, 3, e.target.value)} variant="outlined"/>
                              </MDBox>
                            </MDBox>
                          )}
                        </MDBox>
                      ))}
                      </MDBox>
                    </div>
                    }

                  {queExist &&

                    <div className='form-container'>
                      <MDBox className="ilanFormInputBox">
                        <InputLabel className="ilanFormInputLabel queInputLabel" >Adaya sorulacak sorular?</InputLabel>

                        {questions.length <=4 && 
                          <MDButton type="button" variant="gradient" color="secondary" onClick={() => handleAddQuestion('text')}>
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            &nbsp;Yeni soru ekle
                          </MDButton>
                        }
    
                        {questions.length > 4 && 
                          <MDButton type="button" variant="gradient" color="secondary" disabled>
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            &nbsp;Daha fazla soru ekleyemezsiniz
                          </MDButton>
                        }

                        {questions.map((question, index) => (
                          <MDBox key={index}>

                            <MDBox className="ilanFormQueBox">
                              <div style={{width:"50%"}}>
                              <InputLabel className="ilanFormInputLabel" htmlFor={"queType" + String(index)}>Soru tipini seçiniz</InputLabel>
                              <Select
                                id={"queType" + String(index)}
                                value={question.type}
                                className="ilanFormSelect"
                                onChange={(e) => handleQuestionChange(index, 'type', e.target.value)} required>
                                <MenuItem value="text">Açık Uçlu</MenuItem>
                                <MenuItem value="multipleChoice">Çoktan Seçmeli</MenuItem>
                              </Select>
                              </div>
    
                              <MDButton className="queInputDelete que-delete-btn" type="button" variant="gradient" color="secondary" onClick={() => handleRemoveQuestion(index)}>
                                  <Icon sx={{ fontWeight: "bold" }}>delete_outline</Icon>
                                  &nbsp;Sil
                              </MDButton> 
    
                            </MDBox>
                            
                            {question.type === 'text' ? (
                              <MDBox>
                                <MDInput className="ilanFormInput" type="text" placeholder="Soruyu yazınız.." value={question.question} onChange={(e) => handleQuestionChange(index, 'question', e.target.value)} variant="outlined" required/>
                              </MDBox>
                            ) : (

                              <MDBox>
                                <MDInput className="ilanFormInput" type="text" placeholder="Soruyu yazınız.." value={question.question} onChange={(e) => handleQuestionChange(index, 'question', e.target.value)} variant="outlined" required/>
    
                                <MDBox className="ilanFormQueBoxCoktan" key="1">
                                  <MDInput className="ilanFormInput" type="text"
                                  placeholder={`Seçenek 1`} value={questions[index].options[0]} 
                                  onChange={(e) => handleAddOption(index, 0, e.target.value)} variant="outlined" required/>
                                </MDBox>
    
    
                                <MDBox className="ilanFormQueBoxCoktan" key="2">
                                  <MDInput className="ilanFormInput" type="text"
                                  placeholder={`Seçenek 2`} value={questions[index].options[1]} 
                                  onChange={(e) => handleAddOption(index, 1, e.target.value)} variant="outlined" required/>
                                </MDBox>
    
    
                                <MDBox className="ilanFormQueBoxCoktan" key="3">
                                  <MDInput className="ilanFormInput" type="text"
                                  placeholder={`Seçenek 3`} value={questions[index].options[2]} 
                                  onChange={(e) => handleAddOption(index, 2, e.target.value)} variant="outlined"/>
                                </MDBox>
    
    
                                <MDBox className="ilanFormQueBoxCoktan" key="4">
                                  <MDInput className="ilanFormInput" type="text"
                                  placeholder={`Seçenek 4`} value={questions[index].options[3]} 
                                  onChange={(e) => handleAddOption(index, 3, e.target.value)} variant="outlined"/>
                                </MDBox>
                              </MDBox>
                            )}
                          </MDBox>
                        ))} 
                      </MDBox>
                    </div>
                  }
                </MDBox>
              
              </Card>
            }

            {!ilans &&
              <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                  <ActivityIndicator size="large" color="#40ab9a" />
              </View>
            }


            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );

  }

}

export default IlaniDuzenleDash;
