import React from "react";

import PageLayout from "examples/LayoutContainers/PageLayout";

const Unauthorized = () => {
  return (
    <PageLayout>
      <article style={{ padding: "100px" }}>
        <h1>Üzgünüz!</h1>
        <p>Bu sayfayı görebilmek için izinli değilsiniz.</p>
      </article>
    </PageLayout>
  );
};

export default Unauthorized;
