import { useState, useEffect } from "react";
import { View, ActivityIndicator } from 'react-native';

import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

function DashboardIsveren() {

  const [userData, setUserData] = useState(false);
  const [gelenData, setGelen] = useState({});
  const [olumluData, setOlumlu] = useState({});
  const [gorusmeData, setGorusme] = useState({});

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  useEffect(() => {

    var list_obj = {
      'uid': uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/get-isveren-specific', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
      }
      else {
        setUserData(data.info)

        const gelenLabel = []
        const gelenRows = []

        if(data.gelen !== false) {
          data.gelen.map(row => {
            gelenLabel.push(row[0]);
            gelenRows.push(row[1]);
          })

          const tmp_gelen = {
            labels: gelenLabel,
            datasets: { label: "Gelen Başvuru & Öneri Sayısı", data: gelenRows}
          }

          setGelen(tmp_gelen)
        }
        else {
          setGelen({})
        }


        const olumluLabel = []
        const olumluRows = []

        if(data.olumlu !== false) {
          data.olumlu.map(row => {
            olumluLabel.push(row[0]);
            olumluRows.push(row[1]);
          })

          const tmp_olumlu = {
            labels: olumluLabel,
            datasets: { label: "Başarılı Eşleşme Sayısı", data: olumluRows}
          }

          setOlumlu(tmp_olumlu)
        }
        else {
          setOlumlu({})
        }


        const gorusmeLabel = []
        const gorusmeRows = []

        if(data.gorusmedekiler !== false) {
          data.gorusmedekiler.map(row => {
            gorusmeLabel.push(row[0]);
            gorusmeRows.push(row[1]);
          })

          const tmp_gorusme = {
            labels: gorusmeLabel,
            datasets: { label: "Görüşmeye Çağrılan Aday Sayısı", data: gorusmeRows}
          }
  
          setGorusme(tmp_gorusme)
        }
        else {
          setGorusme({})
        }

      }
    })
    .catch(err => {
        console.log(err);
    });

  }, [uid]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>

          {userData && 
            <>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="format_list_bulleted"
                    title="Verilen ilan sayısı"
                    count={userData?.ilanSayisi}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Şimdi güncellendi",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="check_circle"
                    color="warning"
                    title="İşe aldığın adaylar"
                    count={userData?.iseAlinan}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Şimdi güncellendi",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="open_in_new"
                    title="Açık ilan sayısı"
                    count={userData?.acikIlanlar}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Şimdi güncellendi",
                    }}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="speaker_notes_off"
                    title="Olumsuz sonuçlananlar"
                    count={userData?.iseAlinmayan}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Şimdi güncellendi",
                    }}
                  />
                </MDBox>
              </Grid>
            </>
          }

        </Grid>
        
        {!userData &&
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="#40ab9a" />
          </View>
        }

        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              {gorusmeData && 
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="warning"
                    title="Görüşmeye Çağrılan Adaylar"
                    date="Şimdi güncellendi"
                    chart={gorusmeData}
                  />
                </MDBox>
              }
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {gelenData &&
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="success"
                    title="Gelen Başvurular & Öneriler"
                    date="Şimdi güncellendi"
                    chart={gelenData}
                  />
                </MDBox>
              }
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {olumluData &&
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="Başarılı Eşleşme Sayısı"
                    date="Şimdi güncellendi"
                    chart={olumluData}
                  />
                </MDBox>
              }
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DashboardIsveren;
