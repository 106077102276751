import { useState, useEffect } from "react";

import { enqueueSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

export default function basvurularimTableData() {
  let navigate = useNavigate();

  const [ilansSum, setİlansSum] = useState();
  const [error, setError] = useState(null);

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  const handleTest = (ilanID) => {
    window.sessionStorage.setItem("ilanid", ilanID);
    navigate('/test', { state: { ilanid: ilanID }, replace: true });
  };

  const Pozisyon = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" textTransform="capitalize" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const Status = ({name}) => (
    <MDBox display="flex" alignItems="center" justifyContent="center" lineHeight={1}>
      <MDTypography className="table-badge-width2" display="block" variant="h6" fontWeight="medium" lineHeight={1}>
        <div className="badge-container" color="warning">
        {name}
        </div>
      </MDTypography>
    </MDBox>
  );


  const Test = ({ilanID, asama, testType, score}) => (
    <>
      {asama == "-" &&
        <MDBox display="flex" justifyContent="center" lineHeight={1}>
          <MDTypography display="block" variant="button" textTransform="capitalize" fontWeight="medium" lineHeight={1}>
            {asama}
          </MDTypography>
        </MDBox>
      }
      {asama == "test" &&
        <MDButton className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => handleTest(ilanID, testType)}>
          <Icon sx={{fontWeight: "bold"}}>alarm</Icon>
          &nbsp;Testi Başlat
        </MDButton> 
      }
      {asama == "expire" &&
        <MDButton className="queInputDelete" type="button" variant="gradient" color="dark" disabled>
          <Icon sx={{fontWeight: "bold"}}>cancel</Icon>
          &nbsp; &nbsp;Test Süresi Doldu
        </MDButton> 
      }
      {asama.includes("score") &&
        <MDButton className="queInputDelete" type="button" variant="gradient" color="light" disabled>
          <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
          &nbsp; &nbsp; Skor : {score.split("-")[1]}
        </MDButton> 
      }
    </>
  );

  Pozisyon.propTypes = {
    name: PropTypes.string
  };

  Status.propTypes = {
    name: PropTypes.string
  };

  Test.propTypes = {
    asama: PropTypes.string,
    testType: PropTypes.string,
    ilanID: PropTypes.string,
    score : PropTypes.string
  };


  useEffect(() => {

    var list_obj = {
      'uid': uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/basvurularim-summary', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
  }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });

        setError(data.message);
      }
      else {
        data['basvurularimInfo'].map((value) => {
          if(value[0] === "Görüşme takvimi oluşturuldu"){
            value[0] = "Firma Görüşmesine Davet Ediliyorsunuz"
          }
        })
        data['basvurularimInfo'].map((row) => {
          if(row[5] === 1) {
            row[4] = "Firma Bilgisi Gizli"
          }
        })

        setİlansSum(data['basvurularimInfo']);
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid]);

  if(error) {
    return {columns: [
      { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", align: "left" },
      { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
      { Header: "Güncel Durumu", accessor: "status", align: "center" },
      { Header: "Test Aşaması", accessor: "test", align: "center" },
    ],
    rows: [{}]
    }
  }
  else {
    return {
      columns: [
        { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", align: "left" },
        { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
        { Header: "Güncel Durumu", accessor: "status", align: "center" },
        { Header: "Test Aşaması", accessor: "test", align: "center" },
      ],
      rows: 
          ilansSum?.map((ilan) => (  
            {
              pozisyonUnvani: <Pozisyon name={ilan[3]} />,
              sirketIsmi: <Pozisyon name={ilan[4]} />,
              status: <Status name={ilan[0]} />,
              test: <Test asama={ilan[10]} testType={ilan[6]} ilanID={ilan[9]} score={ilan[8]} />
            }
          ))
    };

  }
}