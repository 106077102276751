// @mui icons
import Icon from "@mui/material/Icon";
import AdminYonetimi from "layouts/admin-yonetimi";
import DashboardAdmin from "layouts/dashboardAdmin";
import IlanVerDash from "layouts/ilan-ver";
import KullaniciYonetimi from "layouts/kullanici-yonetimi";
import TestEkle from "layouts/test-ekle";
import AdayTestSkorlari from "layouts/aday-test-skorlari";
import TestYonetimi from "layouts/test-yonetimi";

const routesAdmin = [
  {
    type: "collapse",
    name: "Tüm İlanlar & Onay",
    key: "admin",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/admin",
    component: <DashboardAdmin />,
  },
  {
    type: "collapse",
    name: "İlan Ekle",
    key: "ilan-ver",
    icon: <Icon fontSize="small">post_add</Icon>,
    route: "/ilan-ver",
    component: <IlanVerDash />,
  },
  {
    type: "collapse",
    name: "Kullanıcı Yönetimi",
    key: "kullanici-yonetimi",
    icon: <Icon fontSize="small">compare_arrows</Icon>,
    route: "/kullanici-yonetimi",
    component: <KullaniciYonetimi />,
  },
  {
    type: "collapse",
    name: "Admin Yönetimi",
    key: "admin-yonetimi",
    icon: <Icon fontSize="small">supervisor_account</Icon>,
    route: "/admin-yonetimi",
    component: <AdminYonetimi />,
  },
  {
    type: "collapse",
    name: "Test Ekle",
    key: "test-ekle",
    icon: <Icon fontSize="small">question_answer</Icon>,
    route: "/test-ekle",
    component: <TestEkle />,
  },
  {
    type: "collapse",
    name: "Aday Test Skorları",
    key: "aday-test-skorlari",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/aday-test-skorlari",
    component: <AdayTestSkorlari />,
  },
  {
    type: "collapse",
    name: "Test Yönetimi",
    key: "test-yonetimi",
    icon: <Icon fontSize="small">edit_attributes</Icon>,
    route: "/test-yonetimi",
    component: <TestYonetimi />,
  },
  {
    type: "collapse",
    name: "İletişim",
    key: "more",
    icon: <Icon fontSize="small">more_horiz</Icon>,
    route: "https://smart-match.net/bize-ulasin/",
  }
];

export default routesAdmin;
