import React from 'react';
import { ReactComponent as FailIcon } from "assets/images/small-logos/fail.svg";

import PageLayout from "examples/LayoutContainers/PageLayout";

function Failure() {

    return (
        <PageLayout>
            <article style={{ padding: "100px" }}>
                <h1 className='text-center'>Ödemeniz başarısız oldu 😞</h1>
                <h3 className="text-center">Lütfen tekrar deneyiniz.</h3>
                <div className="card-body">
                    <FailIcon className="payment-icon" />
                </div>
            </article>
        </PageLayout>
    );

}

export default Failure;