import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { enqueueSnackbar } from 'notistack';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import PropTypes from 'prop-types';

export default function adayTestSkorTableData() {

  let navigate = useNavigate();

  const [error, setError] = useState(null);
  const [userScoredTests, setUserScoredTests] = useState();

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  const TextBoxField = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  TextBoxField.propTypes = {
    name: PropTypes.string
  };

  const handleShowUserAnswers = (ilanID, adayUid, testTitle) => {
    navigate('/aday-test-cevaplari', { state: { ilanid: ilanID, adayUid: adayUid, testTitle: testTitle }, replace: true });
  };


  useEffect(() => {

    const list_obj = {
      "uid": uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/users-scored-tests', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
              
      if(data.result === false) {

        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });

        setError(data.message);
      }
      else {
        setUserScoredTests(data['scoredTest']);
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid]);


  if(error) {
    return {columns: [
      { Header: "Aday İsmi", accessor: "aday", width: "25%", align: "left" },
      { Header: "Test İsmi", accessor: "testTitle", align: "left" },
      { Header: "Test Skoru", accessor: "score", align: "left" },
      { Header: "Şirket İsmi", accessor: "compName", align: "left" },
      { Header: "Pozisyon Ünvanı", accessor: "pozisyon", align: "center" },
      { Header: "Cevap Detayları", accessor: "detay", align: "center" },
    ], rows: [{}]}
  }
  else {
    return {
      columns: [
        { Header: "Aday İsmi", accessor: "aday", width: "25%", align: "left" },
        { Header: "Test İsmi", accessor: "testTitle", align: "left" },
        { Header: "Test Skoru", accessor: "score", align: "left" },
        { Header: "Şirket İsmi", accessor: "compName", align: "left" },
        { Header: "Pozisyon Ünvanı", accessor: "pozisyon", align: "center" },
        { Header: "Cevap Detayları", accessor: "detay", align: "center" },
      ],
      rows: 
        userScoredTests?.map((test, index) => {
            return ({
              aday: <TextBoxField name={test[4]} />,
              testTitle: <TextBoxField name={test[1]} />,
              score: <TextBoxField name={test[0]} />,
              compName: <TextBoxField name={test[2]} />,
              pozisyon: <TextBoxField name={test[3]} />,
              detay: (
                <MDButton key={index} className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => handleShowUserAnswers(test[5], test[6], test[7])}>
                  &nbsp;Cevapları Listele
                </MDButton> 
              ),
            })
          })
    };
  }

}              