import { View, ActivityIndicator } from 'react-native';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import onerilerimTableData from "layouts/onerilerim/data/onerilerimTableData";

import coinIcon from "assets/images/icons/smart_coin.png";

function Onerilerim() {
  const { details, columns, rows } = onerilerimTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  className="ilanFormHeader"
                  coloredShadow="info">
                  <MDTypography variant="h6" color="white">
                    Önerilerim 
                  </MDTypography>

                  {details &&
                  <div>
                    <MDButton variant="gradient" color="dark" type="text" className="header-margin">
                      <Icon sx={{ fontWeight: "bold", marginRight: "0.4em" }}><img className="smart-alt" alt="smart_coin" src={coinIcon} /></Icon>
                      &nbsp;SmartCoin: {details[0]}
                    </MDButton>
                      
                    <MDButton variant="gradient" color="dark" type="text" ml={1}>
                      <Icon sx={{ fontWeight: "bold" }}>compare_arrows</Icon>
                      &nbsp;Başarılı Eşleşme: {details[1]}
                    </MDButton>      
                  </div>

                  }

                </MDBox>
                <MDBox pt={3}>
                  {columns && rows &&
                      <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  }
                  { !columns || !rows &&
                    <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                        <ActivityIndicator size="large" color="#40ab9a" />
                    </View>
                  }
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>


      <Footer />
    </DashboardLayout>
  );
}

export default Onerilerim;
