import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import typography from "assets/theme/base/typography";

function Footer({ light }) {
  const { size } = typography;

  return (
    <MDBox className="mobile-footer" position="absolute" width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          px={1.5}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? "dark" : "text"}
            fontSize={size.sm}>
            &copy; 2023 - 
            <Link href="https://smart-match.net/" target="_blank">
              <MDTypography variant="button" fontWeight="medium" color={"dark"}>
                &nbsp;Smart Match IK&nbsp;
              </MDTypography>
            </Link>
          </MDBox>
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}>
            <MDBox component="li" px={2} lineHeight={1}>
              <Link href="https://smart-match.net/" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={"dark"}
                >
                  Hakkımızda
                </MDTypography>
              </Link>
            </MDBox>
            <MDBox component="li" pl={2} lineHeight={1}>
              <Link href="https://smart-match.net/kvkk/" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={"dark"}>
                  KVKK
                </MDTypography>
              </Link>
            </MDBox>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;

// <MDBox className="deneme" position="relative" width="100%" py={2} px={2} bgColor="#ebebec">

// position: relative;
//     width: 100%;
//     /* bottom: 0.8em; */
//     border-top: 1px solid #d2d6da;
//     padding: 16px;
//     /* padding-bottom: 32px; */
//     opacity: 1;
//     background: #ebebec;
//     color: #344767;
//     border-radius: none;
//     box-shadow: none;
//     /* margin-top: 2em; */
//     /* top: 2em;