import PageLayout from "examples/LayoutContainers/PageLayout";

const Missing = () => {
    return (
        <PageLayout>
            <article style={{ padding: "100px" }}>
                <h1>Oops!</h1>
                <p>Sayfa Bulunamadı.</p>
            </article>
        </PageLayout>
    )
}

export default Missing;
