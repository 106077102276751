import { useState, useEffect } from "react";

import { enqueueSnackbar } from 'notistack';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PropTypes from 'prop-types';

export default function onerilerimTableData() {

  const [ilansSum, setİlansSum] = useState();
  const [oneriDetails, setOneriDetails] = useState(null);

  const [error, setError] = useState(null);

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  const Pozisyon = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" textTransform="capitalize" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const Status = ({name}) => (
    <MDBox display="flex" alignItems="center" justifyContent="center" lineHeight={1}>
      <MDTypography className="table-badge-width" display="block" variant="h6" fontWeight="medium" lineHeight={1}>
        <div className="badge-container" color="warning">
        {name}
        </div>
      </MDTypography>
    </MDBox>
  );

  Pozisyon.propTypes = {
    name: PropTypes.string
  };

  Status.propTypes = {
    name: PropTypes.string
  };

  useEffect(() => {

    var list_obj = {
      'uid': uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/onerilerim-summary', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });

        setError(data.message);
      }
      else {
        setOneriDetails([data.oneriDetails.smartcoin, data.oneriDetails.eslesme])

        data['onerilerimInfo'].map((value) => {
          if(value[0] === "Görüşme takvimi oluşturuldu"){
            value[0] = "Adayınız Firma Görüşmesine Davet Edildi"
          }
          else if(value[0] === "Uzun Liste") {
            value[0] = "Adayınız Uzun Listede"
          }
          else if(value[0] === "Kısa Liste") {
            value[0] = "Adayınız Kısa Listede"
          }
          else if(value[0] === "Olumsuz") {
            value[0] = "Adayınız firma tarafından olumsuz değerlendirildi"
          }
          else if(value[0] === "Olumlu") {
            value[0] = "Adayınız eşleşti"
          }
          else {
            console.log("...")
          }


          if(value[5] === 1) {
            value[4] = "Firma Bilgisi Gizli"
          }
        })
        setİlansSum(data['onerilerimInfo']);
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid]);

  if(error) {
    return {
      columns: [
        { Header: "Önerilen Kişi Adı", accessor: "onerilenAd", align: "left" },
        { Header: "Önerilen Kişi Soyadı", accessor: "onerilenSoyad", align: "left" },
        { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", align: "left" },
        { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
        { Header: "Güncel Durumu", accessor: "status", align: "center" },
      ],
      rows: [{}]
    }
  }
  else {
    return {
      details: oneriDetails,
      columns: [
        { Header: "Önerilen Kişi Adı", accessor: "onerilenAd", align: "left" },
        { Header: "Önerilen Kişi Soyadı", accessor: "onerilenSoyad", align: "left" },
        { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", align: "left" },
        { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
        { Header: "Güncel Durumu", accessor: "status", align: "center" },
      ],
      rows: 
          ilansSum?.map((ilan) => (
            {
              onerilenAd: <Pozisyon name={ilan[1]} />,
              onerilenSoyad: <Pozisyon name={ilan[2]} />,
              pozisyonUnvani: <Pozisyon name={ilan[3]} />,
              sirketIsmi: <Pozisyon name={ilan[4]} />,
              status: <Status name={ilan[0]} />,
            }
          ))
    };
  }
}