/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import DashboardAday from "layouts/dashboardAday";
import DashboardIsveren from "layouts/dashboardIsveren";
import IlanVerDash from "layouts/ilan-ver";
import Ilanlarim from "layouts/ilanlarim";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import AdminSignIn from "layouts/authentication/admin-sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password/cover";
import Basvurular from "layouts/basvurular";
import Onerilerim from "layouts/onerilerim";
import Basvurularim from "layouts/basvurularim";
import Basvur from "layouts/basvur-oner";
import IlanaBasvur from "layouts/ilana-basvur";
import IlanaOner from "layouts/ilana-oner";
import IlanDetayi from "layouts/ilan-detayi";
import IlaniDuzenleDash from "layouts/ilani-duzenle";
import Authentication from "pages/Authentication";
import Unauthorized from "pages/Unauthorized";
import Success from "pages/Success";
import Failure from "pages/Failure";
import AdaySignUp from "pages/terms/AdaySignUp";
import BasariliEslesmeler from "layouts/basarili-eslesmeler";
import TestKopyala from "layouts/test-kopyala";
// @mui icons
import Icon from "@mui/material/Icon";
import DashboardAdmin from "layouts/dashboardAdmin";
import AdminIlanDetayi from "layouts/admin-ilan-detayi";
import KullaniciYonetimi from "layouts/kullanici-yonetimi";
import AdminYonetimi from "layouts/admin-yonetimi";
import IsverenSignUp from "pages/terms/IsverenSignUp";
import CvPaylasimi from "pages/terms/CvPaylasma";
import TestDash from "layouts/test";
import TestEkle from "layouts/test-ekle";
import AdayTestSkorlari from "layouts/aday-test-skorlari";
import AdayTestCevaplari from "layouts/aday-test-cevaplari";
import TestYonetimi from "layouts/test-yonetimi";

const routes = [
  {
    type: "collapse",
    name: "Aday Ana Sayfası",
    key: "aday-anasayfasi",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/aday-anasayfasi",
    component: <DashboardAday />,
  },
  {
    type: "collapse",
    name: "İşveren Ana Sayfası",
    key: "isveren-anasayfasi",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/isveren-anasayfasi",
    component: <DashboardIsveren />,
  },
  {
    type: "collapse",
    name: "İlan Detayı",
    key: "ilan-detayi",
    // icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/ilan-detayi",
    component: <IlanDetayi />,
  },
  {
    type: "collapse",
    name: "İlan Ver",
    key: "ilan-ver",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ilan-ver",
    component: <IlanVerDash />,
  },
  {
    type: "collapse",
    name: "Test Ekle",
    key: "test-ekle",
    icon: <Icon fontSize="small">question_answer</Icon>,
    route: "/test-ekle",
    component: <TestEkle />,
  },
  {
    type: "collapse",
    name: "Aday Test Skorları",
    key: "aday-test-skorlari",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/aday-test-skorlari",
    component: <AdayTestSkorlari />,
  },
  {
    type: "collapse",
    name: "Aday Test Cevapları",
    key: "aday-test-cevaplari",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/aday-test-cevaplari",
    component: <AdayTestCevaplari />,
  },
  {
    type: "collapse",
    name: "Test Yönetimi",
    key: "test-yonetimi",
    icon: <Icon fontSize="small">edit_attributes</Icon>,
    route: "/test-yonetimi",
    component: <TestYonetimi />,
  },
  {
    type: "collapse",
    name: "İlanı Düzenle",
    key: "ilani-duzenle",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ilani-duzenle",
    component: <IlaniDuzenleDash />,
  },
  {
    type: "collapse",
    name: "Testi Kopyala",
    key: "test-kopyala",
    // icon: <Icon fontSize="small">table_view</Icon>,
    route: "/test-kopyala",
    component: <TestKopyala />,
  },
  {
    type: "collapse",
    name: "İlanlarım",
    key: "ilanlarim",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/ilanlarim",
    component: <Ilanlarim />,
  },
  {
    type: "collapse",
    name: "Başarılı eşleşmeler",
    key: "basarili-eslesmeler",
    icon: <Icon fontSize="small">check_circle_outline</Icon>,
    route: "/basarili-eslesmeler",
    component: <BasariliEslesmeler />,
  },
  {
    type: "collapse",
    name: "Kayıt Ol",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Giriş Yap",
    key: "sign-in",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Admin Girişi",
    key: "admin-sign-in",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/admin-sign-in",
    component: <AdminSignIn />,
  },
  {
    type: "collapse",
    name: "Tüm İlanlar & Onay",
    key: "admin",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/admin",
    component: <DashboardAdmin />,
  },
  {
    type: "collapse",
    name: "Admin İlan Detayı",
    key: "admin-ilan-detayi",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/admin-ilan-detayi",
    component: <AdminIlanDetayi />,
  },
  {
    type: "collapse",
    name: "Kullanıcı Yönetimi",
    key: "kullanici-yonetimi",
    icon: <Icon fontSize="small">compare_arrows</Icon>,
    route: "/kullanici-yonetimi",
    component: <KullaniciYonetimi />,
  },
  {
    type: "collapse",
    name: "Admin Yönetimi",
    key: "admin-yonetimi",
    icon: <Icon fontSize="small">supervisor_account</Icon>,
    route: "/admin-yonetimi",
    component: <AdminYonetimi />,
  },
  {
    type: "collapse",
    name: "Profilim",
    key: "profil",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profil",
    component: <Profile />,
  },
    {
    type: "collapse",
    name: "Şifre Sıfırla",
    key: "reset-password",
    icon: <Icon fontSize="small">lock</Icon>,
    route: "/authentication/reset-password",
    component: <ResetPassword />,
  },
  {
    type: "collapse",
    name: "Başvur / Öner",
    key: "basvur-oner",
    icon: <Icon fontSize="small">touch_app_icon</Icon>,
    route: "/basvur-oner",
    component: <Basvur />,
  },
  {
    type: "collapse",
    name: "İlana Başvur",
    key: "ilana-basvur",
    // icon: <Icon fontSize="small">touch_app_icon</Icon>,
    route: "/ilana-basvur",
    component: <IlanaBasvur />,
  },
  {
    type: "collapse",
    name: "İlana Öner",
    key: "ilana-oner",
    // icon: <Icon fontSize="small">touch_app_icon</Icon>,
    route: "/ilana-oner",
    component: <IlanaOner />,
  },
  {
    type: "collapse",
    name: "Başvurularım",
    key: "basvurularim",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/basvurularim",
    component: <Basvurularim />,
  },
  {
    type: "collapse",
    name: "Başvurular",
    key: "basvurular",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/basvurular",
    component: <Basvurular />,
  },
  {
    type: "collapse",
    name: "Test",
    key: "test",
    // icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/test",
    component: <TestDash />,
  },
  {
    type: "collapse",
    name: "Önerilerim",
    key: "onerilerim",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/onerilerim",
    component: <Onerilerim />,
  },
  {
    route: "/",
    key: "default",
    component: <SignIn />,
  },
  {
    route: "/authentication/unverified",
    component: <Authentication />,
  },
  {
    route: "/unauthorized",
    component: <Unauthorized />,
  },
  {
    route: "/payment/success",
    component: <Success />,
  },
  {
    route: "/payment/failure",
    component: <Failure />,
  },
  {
    route: "/terms/aday-sign-up",
    component: <AdaySignUp />,
  },
  {
    route: "/terms/isveren-sign-up",
    component: <IsverenSignUp />,
  },
  {
    route: "/terms/cv-paylasimi",
    component: <CvPaylasimi />,
  }
];

export default routes;