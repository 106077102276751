import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Text, View, ActivityIndicator } from 'react-native';

// @mui material components
import Card from "@mui/material/Card";

import validator from 'validator';
import { enqueueSnackbar } from 'notistack';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg_03.png";

const bcrypt = require('react-native-bcrypt');

function ResetPassword() {

  let navigate = useNavigate();

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [pass1, setPass1] = useState(null);
  const [passError, setPassError] = useState(null);

  const validatePass = (e) => {
    var pass = e.target.value;

    if (validator.isStrongPassword(pass, { 
      minLength: 8, minLowercase: 1, 
      minUppercase: 1, minNumbers: 1, minSymbols: 1 })) { 

      setPassError(null) 
      setPass1(pass)
    } 
    else { 
      setPassError('Şifreniz en az 8 karakter olmalıdır ve içinde şunlardan en az birer tanesini barındırmalıdır: küçük harf, büyük harf, sayı, sembol.') 
    } 
  }

  const comparePasswords = (e) => {

    var pass2 = e.target.value;

    if(pass1 === pass2) {
      setPassError(null)
    }
    else {
      setPassError("Şifreler birbiri ile uyuşmamaktadır.") 
    }

  }

  const handleResetPassword = (e) => {

    setIsLoading(true);
    var salt = bcrypt.genSaltSync(10);

    const resetEmail = e.target.resetEmail.value;
    const resetPass = bcrypt.hashSync(e.target.resetPass.value, salt);

    const list_obj = {
      "resetEmail": resetEmail,
      "resetPass": resetPass
    }

    fetch(process.env.REACT_APP_REQ_IP + '/reset', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
        
        setIsLoading(false);

        if(data.result === true) {
            enqueueSnackbar(data.message, { 
                variant: 'success',
                persist: false
            });

            navigate("/authentication/sign-in", { replace: true });
        }
        else {
            enqueueSnackbar(data.message, { 
                variant: 'error',
                persist: false
            });
        }

    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });

  }

  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#40ab9a" />
        </View>
    );
  }

  else if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
          </View>
      );
  }
  else {
    return (
      <BasicLayout coverHeight="50vh" image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center">
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Şifre Sıfırlama
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Maksimum 2 dk içinde doğrulama için e-posta alacaksınız.
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleResetPassword}>
  
              <MDBox mb={4}>
                <MDInput type="text" label="Email" variant="standard" required id="resetEmail" fullWidth/>
              </MDBox>

              <MDTypography mb={2} className="signUpErrorMsg" variant="h6" color="error">{passError}</MDTypography>
  
              <MDBox mb={4}>
                <MDInput type="password" label="Yeni Şifre" variant="standard" required id="resetPass" fullWidth onChange={(e) => validatePass(e)}/>
              </MDBox>
  
              <MDBox mb={4}>
                <MDInput type="password" label="Yeni Şifre (Doğrulama)" variant="standard" required id="resetPassConf" fullWidth onChange={(e) => comparePasswords(e)}/>
              </MDBox>
  
  
              <MDBox mt={6} mb={1}>
                {passError && 
                  <MDButton type="submit" variant="gradient" color="dark" fullWidth disabled>
                    Sıfırla
                  </MDButton>
                }
                {!passError && 
                  <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                  Sıfırla
                  </MDButton>
                }

              </MDBox>
  
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="dark"
                    fontWeight="medium"
                    textGradient>
                    Giriş&apos;e dönmek için tıkla
                  </MDTypography>
                </MDTypography>
              </MDBox>
  
  
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    );
  }
  
}

export default ResetPassword;
