import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Badge from '@mui/material/Badge';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import routes from "routes";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [definedRoutes, setDefinedRoutes] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const uid = window.sessionStorage.getItem("uid");
  const nameSurname = window.sessionStorage.getItem("nameSurname");

  useEffect(() => {

    var tmpDefinedRoutes={}
    Object.values(routes).map(el => {
      if(el['name']) {
        tmpDefinedRoutes[el['route']] = el['name']
      }
    })
    setDefinedRoutes(tmpDefinedRoutes);

    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  
  const [invisible, setInvisible] = useState(false);
  const [userNotifications, setUserNotifications] = useState(null);
  const [testNotifications, setTestNotifications] = useState(null);
  const userType = window.sessionStorage.getItem("userType");

  const handleOpenMenu = (event) => {
    setInvisible(true);
    setOpenMenu(event.currentTarget)
  };

  const handleCloseMenu = () => setOpenMenu(false);

  const atoken = window.sessionStorage.getItem("access_token");

  useEffect(() => {

    const list_obj = {
      "uid": uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/get-user-notifications', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {

      if(data.result === true) {
        if(data.info.length > 0 || data.testInfo.length > 0) {

            setInvisible(false);
            setUserNotifications(data.info);
            setTestNotifications(data.testInfo);
        }
        else {
          setInvisible(true);
          setUserNotifications(null);
        }
      }
      else {
        setInvisible(true);
        setUserNotifications(null);
      }
  
    })
    .catch(err => {
        console.log(err);
    });

  }, [uid]);


  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}>

        {userNotifications !== null && userNotifications?.map((value, index) => (
          <NotificationItem key={index} icon={<Icon>email</Icon>} title={"Yeni ilan: " + value[2] + ' - ' + value[3]} />
        ))
        }

        {testNotifications !== null && testNotifications?.map((value, index) => (

          <MDTypography
            component="a"
            sx={{ ml: -1, cursor: "pointer", userSelect: "none" }}
            href="/basvurularim"
            variant="button"
            fontWeight="regular"
            style={{margin: 0}}
            key={index}>
              <NotificationItem style={{ textWrap: "wrap", width: 400}} icon={<Icon>access_time</Icon>} title={value[3] + " pozisyonuna yaptığınız başvurunuz için " + value[2] + " firması, tarafınıza test tanımlaması yapmıştır. " + value[0] +  " başlıklı testi yanıtlamak için 2 iş günü süreniz bulunmaktadır."} />
          </MDTypography> 
        ))
        }

        {userNotifications === null &&
          <NotificationItem icon={<Icon>email</Icon>} title="Yeni ilan bulunmamaktadır." />
        }
      
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(dark.main, 0.6) : dark.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}>
    
      <Toolbar sx={(theme) => navbarContainer(theme)}>

        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {definedRoutes && 
            <Breadcrumbs icon="home" title={definedRoutes['/' + route]} route={route} light={light} />
          }
        </MDBox>

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox display="flex" color={light ? "white" : "inherit"}>
              <MDTypography className="ilan-card-desc" component="div" variant="button" color="dark" fontWeight="light">
                Merhaba {nameSurname}
              </MDTypography>
              <Link className="header-acc-link" to="/profil">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              {userType == "aday" &&
              <>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}>
  
                  <Badge color="dark" variant="dot" invisible={invisible}>
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </Badge>
                </IconButton>                        
                {renderMenu()}
              </>
              }
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
