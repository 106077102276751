// Material Dashboard 2 React layouts
import DashboardIsveren from "layouts/dashboardIsveren";
import IlanVerDash from "layouts/ilan-ver";
import Ilanlarim from "layouts/ilanlarim";
import BasariliEslesmeler from "layouts/basarili-eslesmeler";

// @mui icons
import Icon from "@mui/material/Icon";

const routesIsveren = [
  {
    type: "collapse",
    name: "Anasayfa",
    key: "isveren-anasayfasi",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/isveren-anasayfasi",
    component: <DashboardIsveren />,
  },
  {
    type: "collapse",
    name: "İlan Ver",
    key: "ilan-ver",
    icon: <Icon fontSize="small">post_add</Icon>,
    route: "/ilan-ver",
    component: <IlanVerDash />,
  },
  {
    type: "collapse",
    name: "İlanlarım",
    key: "ilanlarim",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/ilanlarim",
    component: <Ilanlarim />,
  },
  {
    type: "collapse",
    name: "Başarılı eşleşmeler",
    key: "basarili-eslesmeler",
    icon: <Icon fontSize="small">check_circle_outline</Icon>,
    route: "/basarili-eslesmeler",
    component: <BasariliEslesmeler />,
  },
  {
    type: "collapse",
    name: "İletişim",
    key: "more",
    icon: <Icon fontSize="small">more_horiz</Icon>,
    route: "https://smart-match.net/bize-ulasin/",
  }
];

export default routesIsveren;
