import { useState, useEffect } from "react";
import { View, ActivityIndicator } from 'react-native';

import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import IlanCards from "examples/Cards/IlanCards";


function Basvur() {

  const [error, setError] = useState(null);

  const [ilansSum, setİlansSum] = useState();

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  useEffect(() => {

    fetch(process.env.REACT_APP_REQ_IP + '/ilans-summary', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }}).then(res => res.json()).then(data => {
              
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
        setError(data.message);
      }
      else {
        const ilansInfo = JSON.parse(data['ilansInfo']);
        setİlansSum(ilansInfo);
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info">
              <MDTypography variant="h6" color="white">
                İlanlar 
              </MDTypography>
            </MDBox>
      
              {ilansSum &&
                <MDBox className="ilan-cards-container" xs={12} md={6} lg={3}>
                  {ilansSum.map ((row, index) => (
                        <Grid className="ilan-width" item mx={2} my={2} key={index}>
                          <Link className="ilan-card-link" to={"/ilan-detayi?id=" + String(row.ilanID)} key={index}>
                            <IlanCards title={row.pozisyonUnvani} description={row.sirketIsmi} konum={row.ilceSecimi + String('/') + row.calisilacakIl} coin={row.kazanilacakUcret} />
                          </Link>
                        </Grid>
          
                  ))}
                </MDBox>
              }

              {!ilansSum &&
                <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                    <ActivityIndicator size="large" color="#40ab9a" />
                </View>
              }



          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Basvur;
