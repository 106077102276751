import React from 'react';

import PageLayout from "examples/LayoutContainers/PageLayout";

function AdaySignUp() {

    return (
        <PageLayout>
            <article style={{ padding: "100px" }}>
                <h1 className='text-center'>Çalışan Adayı Kişisel Veri Aydınlatma Metni</h1>
                <span>
                <ol>
                    <li>
                        <b>Veri Sorumlusunun Kimliği</b><br/>
                        Smartmatch İnsan Kaynakları Anonim Şirketi (“Şirket”) (internet adresi: www.smart-match.net , faaliyet adresi: Kızılırmak Mah. Next Level No:3A/100 Çankaya/Ankara) ile paylaşmış olduğunuz tüm kişisel verilerinizin özenle işlenerek muhafaza edilmesine ve hukuka uygun olarak korunmasına hassasiyet göstermekteyiz.  Bu aydınlatma metnini 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ve ilgili mevzuat kapsamında Şirket’in kişisel verilerin işlenmesi ve korunmasına yönelik uygulamaları konusunda çalışan adaylarımızı bilgilendirmek için hazırladık. 
                    </li>

                    <li>
                        <b>İşlenen Kişisel Verileriniz</b><br/>
                        Şirketimiz veri sorumlusu sıfatıyla, ticari ilişkilerimiz kapsamında veya sizlerle olan iş ilişkimiz dahilinde, kişisel verilerinizin, işlenmelerini gerektiren amaç çerçevesinde ve bu amaç ile bağlantılı, sınırlı ve ölçülü şekilde, tarafımıza bildirdiğiniz şekliyle kişisel verilerin doğruluğunu ve en güncel halini koruyarak, kaydedileceğini, depolanacağını, muhafaza edileceğini, kanunen bu kişisel verileri talep etmeye yetkili olan kurumlar ile paylaşılacağını, yurtiçi veya yurtdışı üçüncü kişilere aktarılabileceğini, devredilebileceğini, sınıflandırılabileceğini ve Kanun’da sayılan sair şekillerde işlenebileceğini bildiririz.                   
                    
                        <table>
                            <tr>
                                <th>Kişisel Veri Kategorisi</th>
                                <th>KİŞİSEL VERİLER</th>
                            </tr>
                            <tr>
                                <td>Kimlik</td>
                                <td>Ad, soyad, Doğum tarihi, medeni durum, cinsiyet, ehliyet bilgisi</td>
                            </tr>
                            <tr>
                                <td>İletişim</td>
                                <td>Mobil Telefon numarası, iletişim adresi, e-posta adresi</td>
                            </tr>
                            <tr>
                                <td>Özlük, Çalışan adayı ve Mesleki Deneyim Bilgisi</td>
                                <td>Özgeçmiş bilgileri, Askerlik durumu, Öğrenim durumu, Sınav bilgisi(yabancı dil için), Tecrübe bilgisi, Yabancı dil bilgisi, bilgisayar bilgisi Kişilik envanteri, cv bilgisi, işe alım formu, mülakat değerlendirme formu, referans değerlendirme formu, referanslar, Diploma, gidilen kurslar, meslek içi eğitim bilgileri, sertifikalar, varsa özgeçmişinize eklediğiniz her türlü word, excel, sunum dosyaları</td>
                            </tr>
                            <tr>
                                <td>Görsel ve İşitsel Kayıtlar</td>
                                <td>Fotoğraf, İşe alım görüşmesi videosu kamera kayıtları</td>
                            </tr>
                            <tr>
                                <td>Finans</td>
                                <td>IBAN</td>
                            </tr>
                            <tr>
                                <td>Web sitesi kullanım verileri</td>
                                <td>IP adresi, log kayıtları</td>
                            </tr>
                            <tr>
                                <th>Özel Nitelikli Kişisel Veri Kategorisi</th>
                                <th>ÖZEL NİTELİKLİ KİŞİSEL VERİLER </th>
                            </tr>

                            <tr>
                                <td>Sağlık Bilgileri</td>
                                <td>Engellilik durumu, engelliyse kullandığı cihaz ve protez bilgileri,</td>
                            </tr>                           
                             <tr>
                                <td>Ceza Mahkûmiyeti ve Güvenlik Tedbirleri</td>
                                <td>Adli sicil bilgisi</td>
                            </tr>
                        </table>
                    
                    <br/>
                    Sistemlerimizde, açık rıza vermiş olmanız halinde, özgeçmiş oluşturmanız esnasında size sorulan engellilik durumu bilgisi, sağlık verisi olması dolayısıyla , adli sicil bilgisi ise ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili olması dolayısıyla özel nitelikli kişisel veridir. Söz konusu bilgiyi özel politika gerektiren grupların işgücü piyasasına kazandırılmasına yönelik olarak tarafınızdan talep ediyoruz. Açık rıza vermemiş olduğunuz durumda ise, söz konusu veriler sizden talep edilmeyecek ve bu bilgiyi paylaşmayı tercih etmediğiniz durumda, örneğin engelli adayı pozisyonunda istihdam edilmeniz gerekiyorsa, hizmet verdiğimiz işveren yeterli bilgiyi edinememiş olacağı için, Şirketimizin size sunduğu başvuru olanağını, kendi iradenizle sınırlandırmış olacaksınız. Bu durumlarda oluşabilecek herhangi bir hak kaybından Şirket’in sorumlu olmayacağını belirtiriz.
                    <br/>
                    Başvuru esnasında size sorulmayan fakat kendi seçiminizle belirttiğiniz verdiğiniz kişisel verilerinizi de işliyoruz. Bu kapsamda, KVKK izni vermeyi tercih etmediğiniz durumda, özgeçmişte bulunan bilgiler dışında herhangi bir kişisel verinizi özgeçmişinizde belirtmemenizi öneririz.                    
                    </li>

                    <li>
                        <b>Kişisel Verilerin İşlenmesi ve İşleme Amaçları</b><br/>
                        Şirketimizin çalışan adayı olmanız sebebiyle, kişisel verileriniz çalışan adayı / stajyer / öğrenci seçme ve yerleştirme süreçlerinin yürütülmesi, İstihdam yaratmak ve işe alım sürecinize destek vermek,  çalışan adaylarının başvuru süreçlerinin yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesi, iletişim faaliyetlerinin yürütülmesi, insan kaynakları süreçlerinin planlanması sağlanması, Verdiğimiz hizmetlere ilişkin bir şikâyet olduğu takdirde söz konusu şikâyeti sonuçlandırmak, Hizmet kalitemizi arttırmak ve hizmet kontrolü sağlamak, Yeni eleman istihdam edilmesi, adayları inceleme ve istihdam edilecek yeni adayın tespit edilmesi,  Özgeçmişinize alınan notların işverenin adayı daha iyi anlayabilmesi ve tanıyabilmesi için işveren müşterilerimiz ile paylaşılması, Özgeçmişinizde yer verdiğiniz referans kişileriyle verilerin teyit edilmesi, Pozisyonla ne kadar örtüştüğünüzün doğrulanması ve ileriye dönük teyit için özgeçmiş bilgilerinizin kaydedilmesi amaçlarıyla işlenmektedir.
                        <br/>
                        Engelli çalışan kadrosuna başvurmanız ve açık rızanızı vermeniz halinde, sağlık verileriniz dahil olmak üzere özel nitelikli kişisel veriniz çalışan adayı / stajyer / öğrenci seçme ve yerleştirme süreçlerinin yürütülmesi, çalışan adaylarının başvuru süreçlerinin yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesi, iletişim faaliyetlerinin yürütülmesi, insan kaynakları süreçlerinin planlanması amaçlarıyla işlenmektedir.
                        <br/>
                        Açık rızanızın bulunması halinde, kişisel verileriniz ve özel nitelikli kişisel verileriniz gelecekte ortaya çıkabilecek uygun bir pozisyon için özgeçmişinizin değerlendirilmesi ve sizinle iletişime geçilmesi amacıyla 2 yıl süre ile saklanacaktır.
                        <br/>
                        Kişisel verileriniz ayrıca fiziki arşivler ve bilişim sistemlerine nakledilerek hem dijital hem fiziki ortamlarda muhafaza edilebilecektir.
                    </li>

                    <li>
                        <b>İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçlar ile Aktarılabileceği</b><br/>
                        Toplanan kişisel verileriniz, Açık rızanız olması halinde yurtiçi veya yurtdışı üçüncü kişilere aktarılabilecek, Şirket’e yaptığınız başvurunuzun olumsuz değerlendirilmesi halinde, pozisyon değerlendirmeleri için çalıştığımız bağlı veya ortak şirketlere özgeçmişinizle birlikte aktarılabilecek, işbu Aydınlatma Metni’nin 3. maddesinde açıklanan amaçlarla, Bilgi Teknolojileri ve İletişim Kurulu/Kurumu ile diğer resmi kurum ve kuruluşlara, adli ve idari mercilere, personel arayışında olan işveren müşterilerimize, anlaşmalı hukuk bürolarına, tedarikçilerimize, Şirketimizin ortaklarına, tarafınızca yetki verilmiş olan vekil ve temsilcilerinize, aktarılabilecek, Referans göstermeniz halinde, şirket ile paylaştığınız bilgiler, teyit amaçlı referans gösterilen kişiler ile paylaşılabilecektir. 
                    </li>

                    <li>
                        <b>Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</b><br/>
                        Kişisel verileriniz ve özel nitelikli kişisel verileriniz, işbu Aydınlatma Metni’nin 3. maddesinde açıklanan amaçlar ile Kanun’da öngörülen temel ilkelere uygun olarak Kanun’un 5. maddesinde belirtilen; kanunlarda açıkça öngörülmesi, hakkın tesisi, kullanılması veya korunması, sözleşmenin kurulması veya ifası, veri sorumlusunun hukuki yükümlülüğü, Şirket olarak hukuki yükümlülüklerimizi yerine getirmek, aramızdaki istihdama aracılık hizmeti için, Şirket’e başvuruyorsanız aramızda kurulabilecek iş akdinin ifası ve Şirket’in meşru menfaati gereği ve açık rızanızın bulunması hukuki sebeplerine dayalı ve bunlarla sınırlı olarak; otomatik veya otomatik olmayan yöntemlerle, tarafınızca Şirketimize fiziksel ve/veya elektronik ortamda iletilen sözlü veya yazılı bilgilerden, elektronik posta adresimiz, internet sitemiz,  tedarikçilerimiz, iş ortaklarımız, çağrı merkezi, sesli yanıt sistemi, sosyal medya hesaplarımız, resmi kurum ve kuruluşlar, Şirket faaliyetleri kapsamında kullanılan uygulama ve yazılımlardan, mülakat görüşmeleri esnasında yapılan video kayıtları ile toplanabilmektedir.
                    </li>

                    <li>
                        <b>Kişisel Verilerin Korunmasına Yönelik Haklarınız</b><br/>
                        Kanun’un 11. maddesi uyarınca kişisel verilerinizin korunması ile ilgili olarak aşağıdaki haklara sahipsiniz:
                        <ul>
                            <li>Kişisel verinizin işlenip işlenmediğini öğrenme,</li>
                            <li>Kişisel veriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                            <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                            <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                            <li>Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme, düzeltme yapılması halinde verilerin aktarıldığı üçüncü kişilere bilgi verilmesini isteme,</li>
                            <li>Kanun’un 7. maddesi çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme, silme veya yok edilmesi halinde verilerin aktarıldığı üçüncü kişilere bilgi verilmesini isteme,</li>
                            <li>İşlenen verilerin münhasıran otomatik sistemler ile analiz edilmesi sonucunda aleyhinize bir sonuç ortaya çıkması halinde itiraz etme,</li>
                            <li>Kişisel verinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme.</li>
                        </ul>

                        <br/>
                        Yukarıda belirtilen haklarınızı kullanmak ve talepte bulunmak için kendi hazırlayacağınız dilekçenizi (<b><u>Veri Sorumlusuna Başvuru Esas ve Usulleri Tebliği uyarınca bu dilekçenin şunları içermesi zorunludur</u></b>: adınız, soyadınız, TCKN, imza, cevap almak istediğiniz adres veya e-posta adresiniz veya faks numaranız, başvuru tarihiniz, talebinize ilişkin detaylı açıklamalarınız) Kızılırmak Mah. Next Level No:3A/100 Çankaya/Ankara adresimize iletebilirsiniz. 
                        <br/>
                        Başvurunuzu sonuçlandırabilmemiz için bize sunmuş olduğunuz bilgi ve belgelerin doğru ve güncel olması gerekmekte olup, başvurunuz ile ilgili olarak eksiklik tespit edilmesi halinde sizden ek bilgi talep edebilecektir. Başvurunuz sonucunda size temin edeceğimiz bilgi ve belgelerin ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu tarafından belirlenen ücreti ödemeniz gerekebileceği hususunu bilgilerinize sunarız.

                    </li>


                </ol>
                </span>
            </article>

            <article style={{ padding: "30px 100px" }}>
                <h1 className='text-center'>AÇIK RIZA METNİ</h1>
                <span>
                <ol>
                    <li>
                    Şirketiniz tarafından Kişisel Verilerin Korunması Mevzuatı Uyarınca hazırlanmış olan  “Çalışan Adayı Kişisel Veri Aydınlatma Metni”ni okuduğumu, anladığımı ve aydınlatma metninde belirtilen amaçlar ve işleme faaliyetleriyle sınırlı olarak şahsımla ilgili özel nitelikli kişisel verilerim dahil kişisel verilerimin işlenmesine ve aktarılmasına dair bilgilendirildiğimi,  iş görüşmesi esnasında yapılan ve özel nitelikli kişisel verilerimi içeren video kaydı alınmasına ayrıca ve açıkça muvafakat ettiğimi, Şirketiniz bünyesindeki pozisyona yaptığım başvurunun incelenmesi sırasında Şirketiniz ile kendi serbest ve özgür irademle paylaştığım özgeçmişim (CV) , şirketinizde ya da sistem üzerinden doldurduğum İş Başvuru Formu’ nda veya sair evrakta yer alan Kimlik, İletişim, Özlük, Çalışan adayı ve Mesleki Deneyim Bilgisi, görsel ve işitsel kayıtlar, finans ve web sitesi kullanım vb.ne  ilişkin kişisel verilerim ile iş görüşmesi esnasında yapılan ve özel nitelikli kişisel verilerimi içeren  video kaydına istinaden  fiziksel, işitsel ve görsel özel nitelikli kişisel verilerim ile Sağlık Bilgisi ve Ceza Mahkumiyetine ilişkin özel nitelikli kişisel verilerim de dahil olmak üzere kişisel verilerimin aydınlatma metninde belirtilen esaslar, amaçlar ve işleme faaliyetleriyle sınırlı olarak toplanmasına, işlenmesine, saklanmasına, aktarılmasına ve ayrıca İşe alımın gerçekleştirilmemesi halinde gelecekte ortaya çıkabilecek uygun bir pozisyon için özgeçmişimin değerlendirilmesi amacıyla 2 yıl süreyle şirketiniz tarafından muhafaza edilmesine açıkça rıza gösterdiğimi beyan ediyorum. 
                    </li>
                </ol>
                </span>
            </article>
        </PageLayout>
    );

}

export default AdaySignUp;