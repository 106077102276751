import React from 'react';

import PageLayout from "examples/LayoutContainers/PageLayout";

function CvPaylasimi() {

    return (
        <PageLayout>
            <article style={{ padding: "100px" }}>
                <h1 className='text-center'>CV Paylaşım Onayı</h1>
                <span>
                <ol>
                    <li>
                    Smartmatch İnsan Kaynakları A.Ş’ye (Şirket) yapmış olduğum başvuru esnasında bir başka adayın da özgeçmiş bilgisini Şirket ile paylaşmam durumunda, böyle bir paylaşımda bulunmaya yetkili olduğumu, ilgili adayın kişisel verilerini paylaşmam sebebi ile tüm sorumluluğun kendime ait olduğunu, ilgili adaydan gerekli izin ve yetkiyi aldığımı, ilgili adaya gerekli aydınlatmayı yaptığımı, Şirket’in bu sebeple bir zarara uğraması durumunda Şirket’in uğradığı tüm zararın tarafıma rücu edileceğini kabul beyan ve taahhüt ederim.  
                    </li>
                </ol>
                </span>
            </article>
        </PageLayout>
    );

}

export default CvPaylasimi;