import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { View, ActivityIndicator } from 'react-native';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function IlanDetayi() {

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let ilanID = params.get('id')

  const [ilans, setIlans] = useState();

  const atoken = window.sessionStorage.getItem("access_token");

  let navigate = useNavigate();

  const handleBasvur = (ilanID, pozisyonUnvani, sirketIsmi) => {
    window.sessionStorage.setItem("ilanid", ilanID);
    window.sessionStorage.setItem("pozisyonunvani", pozisyonUnvani);
    window.sessionStorage.setItem("sirketismi", sirketIsmi);

    navigate('/ilana-basvur', { state: { ilanid: ilanID, pozisyonunvani: pozisyonUnvani,sirketismi: sirketIsmi }, replace: true });
  };

  const handleOner = (ilanID, pozisyonUnvani, sirketIsmi) => {
    window.sessionStorage.setItem("ilanid", ilanID);
    window.sessionStorage.setItem("pozisyonunvani", pozisyonUnvani);
    window.sessionStorage.setItem("sirketismi", sirketIsmi);

    navigate('/ilana-oner', { state: { ilanid: ilanID, pozisyonunvani: pozisyonUnvani,sirketismi: sirketIsmi }, replace: true });
  };

  useEffect(() => {

    const list_obj = {
      "ilanID": ilanID
    }

    fetch(process.env.REACT_APP_REQ_IP + '/ilans-detay', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
              
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
      }
      else {
        const ilans = JSON.parse(data['ilans']);
        setIlans(ilans[0]);
      }
    })
    .catch(err => {
        console.log(err);
    });

  }, [ilanID]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
          {
            ilans && 
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                className="ilanFormHeader"
                coloredShadow="info">
                <MDTypography variant="h5" color="white">
                {ilans.pozisyonUnvani} ( {ilans.sirketIsmi} )
                </MDTypography>

                <MDBox>
                  <MDButton className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => handleBasvur(ilans.ilanID, ilans.pozisyonUnvani, ilans.sirketIsmi)}>
                    <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
                    &nbsp;Başvur
                  </MDButton> 

                  <MDButton className="queInputDelete" type="button" variant="gradient" color="secondary" onClick={() => handleOner(ilans.ilanID, ilans.pozisyonUnvani, ilans.sirketIsmi)}>
                    <Icon sx={{ fontWeight: "bold" }}>border_color_icon</Icon>
                    &nbsp;Öner
                  </MDButton> 
                </MDBox>
              </MDBox>

              <MDBox pt={3}>

                <p className='ilan-detail-box'>
                  <b>İlan Tarihi : </b> {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(ilans.ilanCreateDate)}
                </p>

                <p className='ilan-detail-box'>
                  <b>Bölüm : </b> {ilans.bolum}
                </p>

                <p className='ilan-detail-box'>
                  <b>Pozisyon : </b> {ilans.pozisyon}
                </p>

                <p className='ilan-detail-box'>
                  <b>Deneyim : </b> {ilans.deneyim}
                </p>

                <p className='ilan-detail-box'>
                  <b>İş Tanımı : </b> {ilans.isTanimi}
                </p>

                <p className='ilan-detail-box'>
                  <b>Çalışılacak Adres : </b> {ilans.ilceSecimi} / {ilans.calisilacakIl}
                </p>

                <p className='ilan-detail-box'>
                  <b>Çalışma Gün Ve Saatleri : </b> {ilans.calismaGun}
                </p>

                <p className='ilan-detail-box'>
                  <b>Çalışma Şekli : </b> {ilans.calismaSekli}
                </p>

                <p className='ilan-detail-box'>
                  <b>Eğitim Durumu : </b> {ilans.egitimDurumu}
                </p>

                <p className='ilan-detail-box'>
                  <b>Yabancı Dil Seviyesi : </b> {ilans.dilSeviyesi}
                </p>

                <p className='ilan-detail-box'>
                  <b>Bilgisayar Yetkinliği : </b> {ilans.bilgSev}
                </p>

                <p className='ilan-detail-box'>
                  <b>Diğer Teknik Yetkinlikler : </b> {ilans.teknikYetk}
                </p>

                <p className='ilan-detail-box'>
                  <b>Gelişimsel Yetkinlikler : </b> {ilans.gelisimsel}
                </p>

                <p className='ilan-detail-box'>
                  <b>Mutlaka Sahip Olunması Gereken 3 Özellik : </b> {ilans.mutlakaSahip}
                </p>

                <p className='ilan-detail-box ilan-detail-bottom'>
                  <b>Önerilen Adayın İşe Yerleşmesi Durumunda Kazanılacak Ücret : </b> ₺{ilans.kazanilacakUcret}
                </p>

              </MDBox>

            </Card>
          }


          {!ilans &&
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#40ab9a" />
            </View>
          }

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default IlanDetayi;
