import React, { useState } from "react";
import { View, ActivityIndicator } from 'react-native';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import validator from 'validator';
import { enqueueSnackbar } from 'notistack';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import adminTableData from "layouts/admin-yonetimi/data/adminTableData";

const bcrypt = require('react-native-bcrypt');

function AdminYonetimi() {
  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { columns, rows } = adminTableData();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [emailError, setEmailError] = useState(null);
  const [passError, setPassError] = useState(null);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError(null);
    } 
    else {
      setEmailError("Lütfen geçerli bir email adresi giriniz!");
    }
  }

  const validatePass = (e) => {
    var pass = e.target.value;

    if (validator.isStrongPassword(pass, { 
      minLength: 8, minLowercase: 1, 
      minUppercase: 1, minNumbers: 1, minSymbols: 1 })) { 

      setPassError(null) 
    } 
    else { 
      setPassError('Şifreniz en az 8 karakter olmalıdır ve içinde şunlardan en az birer tanesini barındırmalıdır: küçük harf, büyük harf, sayı, sembol.') 
    } 
  }


  const handleAddAdmin = (e) => {

    setIsLoading(true);
    var salt = bcrypt.genSaltSync(10);

    const adminNameSurname = e.target.adminNameSurname.value;
    const adminEmail = e.target.adminEmail.value;
    const adminPassword = bcrypt.hashSync(e.target.adminPassword.value, salt);

    const list_obj = { email: adminEmail }

    const register_list_obj = {
      adminNameSurname: adminNameSurname,
      adminEmail: adminEmail,
      adminPassword: adminPassword,
    }

    fetch(process.env.REACT_APP_REQ_IP + '/check-user', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {

      //kullanıcı yoksa
      if(data.result === false) {
        fetch(process.env.REACT_APP_REQ_IP + '/admin-register', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(register_list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);

            //register işlemi başarılıysa
            if(data.result === true) {
              enqueueSnackbar(data.message, { 
                variant: 'success',
                persist: false
              });
            }
            //register işlemi failse
            else{
              enqueueSnackbar(data.message, { 
                variant: 'error',
                persist: false
              });
            }
          })
        }
      //kullanıcı varsa
      else { 
        setIsLoading(false);
        enqueueSnackbar(data.message, { 
          variant: 'info',
          persist: false
        });
      }
    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });

    e.preventDefault();

  }

  // handleAddAdmin

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                className="ilanFormHeader"
                coloredShadow="warning">
                <MDTypography variant="h6" color="white">
                  Admin Listesi
                </MDTypography>

                <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Admin Ekle
                  </MDButton>
              </MDBox>

              <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title">Admin Ekleme</DialogTitle>
                <MDBox component="form" role="form" onSubmit={handleAddAdmin}>


                {isLoading &&        
                  <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                    <ActivityIndicator size="large" color="#40ab9a" />
                  </View>     
                }

                {!isLoading &&
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          Admin eklenmesi, email adresi yazılan kişiye sistemde üst düzey ayarların atanmasını sağlar. Devam etmek istiyor musunuz?
                      </DialogContentText>
    
    
                      <MDBox mb={2}>
                        <MDInput type="text" label="İsim & Soyisim" variant="standard" fullWidth id="adminNameSurname" required/>
                      </MDBox>
          
                      <MDBox mb={2}>
                        <MDInput type="email" label="Email" variant="standard" fullWidth id="adminEmail" required onChange={(e) => validateEmail(e)}/>
                        <MDTypography className="signUpErrorMsg" variant="h6" color="error">{emailError}</MDTypography>
                      </MDBox>
          
                      <MDBox mb={2}>
                        <MDInput type="password" label="Şifre" variant="standard" fullWidth id="adminPassword" required onChange={(e) => validatePass(e)}/>
                        <MDTypography className="signUpErrorMsg" variant="h6" color="error">{passError}</MDTypography>
                      </MDBox>
                  </DialogContent>
                }


                  <DialogActions>
                    <MDButton onClick={handleDialogClose} color="dark" autoFocus>
                      Kapat
                    </MDButton>
                    <MDButton type="submit" color="warning" >
                      Devam Et
                    </MDButton>
                  </DialogActions>
                </MDBox>
              </Dialog>

              <MDBox pt={3}>
                {columns && rows &&
                    <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={true}
                    noEndBorder
                  />
                }
                { !columns || !rows &&
                  <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                      <ActivityIndicator size="large" color="#40ab9a" />
                  </View>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminYonetimi;
