import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { enqueueSnackbar } from 'notistack';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Button from '@mui/material/Button';
import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

export default function adminDashTableData() {

  let navigate = useNavigate();

  const [error, setError] = useState(null);

  const [ilansSum, setİlansSum] = useState();
  const [asamaValues, setAsamaValues] = useState();

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  const handleBasvurular = (ilanID) => {
    window.sessionStorage.setItem("ilanid", ilanID);
    navigate('/basvurular', { state: { ilanid: ilanID }, replace: true });
  };

  const handleOnayla = (ilanID) => {
    const list_obj = {
      "ilanID": ilanID,
      "asama": "yayinda",
    }

    fetch(process.env.REACT_APP_REQ_IP + '/update-ilan-status', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'warning',
          persist: false
        });
      }
      else {
        setAsamaValues([ilanID]);

        enqueueSnackbar(data.message, { 
          variant: 'success',
          persist: false
        });
      }
    })
    .catch(err => {
        setError(err);
    });
  };

  const Duzenle = ({ilanID}) => (
    <MDButton className="queInputDelete" type="button" variant="gradient" color="warning" href={'/ilani-duzenle?id=' + String(ilanID)}>
      <Icon sx={{fontWeight: "bold"}}>edit</Icon>
      &nbsp;Düzenle
    </MDButton> 
  );

  const Pozisyon = ({name=''}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" textTransform="capitalize" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const DurumTXT = ({name=''}) => (
    <MDBox display="flex" alignItems="center" justifyContent="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const Detay = ({ilanID}) => (
    <MDButton className="queInputDelete" type="button" variant="gradient" color="dark" href={'/admin-ilan-detayi?id=' + String(ilanID)}>
      <Icon sx={{fontWeight: "bold"}}>edit</Icon>
      &nbsp;İncele
    </MDButton> 
  );

  Pozisyon.propTypes = {
    name: PropTypes.string
  };

  DurumTXT.propTypes = {
    name: PropTypes.string
  };

  Detay.propTypes = {
    ilanID: PropTypes.string
  };

  Duzenle.propTypes = {
    ilanID: PropTypes.string
  };

  useEffect(() => {

    fetch(process.env.REACT_APP_REQ_IP + '/admin-ilans-summary', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }}).then(res => res.json()).then(data => {
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
        setError(data.message);
      }
      else {
        const ilansInfo = JSON.parse(data['ilansInfo']);
        setİlansSum(ilansInfo);
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid, asamaValues]);

  if(error) {
    return {columns: [
      { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", width: "25%", align: "left" },
      { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
      { Header: "Durum", accessor: "durum", align: "center" },
      { Header: "Detaylar", accessor: "ilanID", align: "center" },
      { Header: "Düzenle", accessor: "duzenle", align: "center" },
      { Header: "Başvurular", accessor: "basvurular", align: "center" }
    ], rows: [{}]}
  }
  else {
    return {
      columns: [
        { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", width: "25%", align: "left" },
        { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
        { Header: "Durum", accessor: "durum", align: "center" },
        { Header: "Detaylar", accessor: "ilanID", align: "center" },
        { Header: "Düzenle", accessor: "duzenle", align: "center" },
        { Header: "Başvurular", accessor: "basvurular", align: "center" }
      ],
      rows: 
          ilansSum?.map((ilan) => {
            if(ilan.ilanStatus !== "onayda") {
              return ({
                pozisyonUnvani: <Pozisyon name={ilan.pozisyonUnvani} />,
                sirketIsmi: <Pozisyon name={ilan.sirketIsmi} />,
                durum:  <DurumTXT name="İlan yayındadır." />,
                ilanID: <Detay ilanID={ilan.ilanID} />,
                duzenle: <Duzenle ilanID={ilan.ilanID} />,
                basvurular: (
                  <MDButton key={ilan.ilanID} className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => handleBasvurular(ilan.ilanID)}>
                    <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
                    &nbsp;Başvuruları Görüntüle
                  </MDButton> 
                )
                // durum: (
                //   <MDButton key={ilan.ilanID} className="queInputDelete" type="button" variant="gradient" color="error" onClick={() => handleKaldir(ilan.ilanID, ilan.pozisyonUnvani, ilan.sirketIsmi)}>
                //     <Icon sx={{fontWeight: "bold"}}>highlight_off</Icon>
                //     &nbsp;Yayından Kaldır
                //   </MDButton> 
                // ),
              })
            }
            else {
              return ({
                pozisyonUnvani: <Pozisyon name={ilan.pozisyonUnvani} />,
                sirketIsmi: <Pozisyon name={ilan.sirketIsmi} />,
                durum: (
                  <MDButton key={ilan.ilanID} className="queInputDelete" type="button" variant="gradient" color="secondary" onClick={() => handleOnayla(ilan.ilanID, ilan.pozisyonUnvani, ilan.sirketIsmi)}>
                    <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
                    &nbsp;Onayla
                  </MDButton> 
                ),
                ilanID: <Detay ilanID={ilan.ilanID} />,
                duzenle: <Duzenle ilanID={ilan.ilanID} />,
                basvurular: (
                  <MDButton key={ilan.ilanID} className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => handleBasvurular(ilan.ilanID)}>
                    <Icon sx={{fontWeight: "bold"}}>check_icon</Icon>
                    &nbsp;Başvuruları Görüntüle
                  </MDButton> 
                )
              })

            }

          }
          
          
          )
    };

  }

}
