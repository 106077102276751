import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import validator from 'validator';
import { enqueueSnackbar } from 'notistack';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg_03.png";

const bcrypt = require('react-native-bcrypt');

function SignUp() {

  let navigate = useNavigate();

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [emailError, setEmailError] = useState(null);
  const [passError, setPassError] = useState(null);

  const [userType, setUserType] = useState('aday');

  let uid = window.sessionStorage.getItem("uid");
  let tmpUserType = window.sessionStorage.getItem("userType");
  
  //if user authenticated
  if(uid !== null && tmpUserType == "aday") {
    navigate("/aday-anasayfasi", { replace: true });
  }
  if(uid !== null && tmpUserType == "isveren") {
    navigate("/isveren-anasayfasi", { replace: true });
  }  

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError(null);
    } 
    else {
      setEmailError("Lütfen geçerli bir email adresi giriniz!");
    }
  }

  const validatePass = (e) => {
    var pass = e.target.value;

    if (validator.isStrongPassword(pass, { 
      minLength: 8, minLowercase: 1, 
      minUppercase: 1, minNumbers: 1, minSymbols: 1 })) { 

      setPassError(null) 
    } 
    else { 
      setPassError('Şifreniz en az 8 karakter olmalıdır ve içinde şunlardan en az birer tanesini barındırmalıdır: küçük harf, büyük harf, sayı, sembol.') 
    } 
  }

  const handleAdaySignUp = (e) => {

    setIsLoading(true);
    var salt = bcrypt.genSaltSync(10);

    const adayNameSurname = e.target?.adayNameSurname?.value;
    const adayEmail = e.target?.adayEmail?.value;
    const adayPassword = bcrypt.hashSync(e.target?.adayPassword?.value, salt);
    const adayJob = e.target?.adayJob?.value;
    const adayTerms = e.target?.adayTerms?.value;

    const list_obj = { email: adayEmail }

    const register_list_obj = {
      adayNameSurname: adayNameSurname,
      adayEmail: adayEmail,
      adayPassword: adayPassword,
      adayJob: adayJob,
      adayTerms: true,
      userType: userType
    }

    fetch(process.env.REACT_APP_REQ_IP + '/check-user', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {

      //kullanıcı yoksa
      if(data.result === false) {
        fetch(process.env.REACT_APP_REQ_IP + '/register', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(register_list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);

            //register işlemi başarılıysa
            if(data.result === true) {
              enqueueSnackbar(data.message, { 
                variant: 'success',
                persist: false
              });

              navigate("/authentication/sign-in", { replace: true }); 

            }

            //register işlemi failse
            else{
              enqueueSnackbar(data.message, { 
                variant: 'error',
                persist: false
              });
            }
          })
        }
      //kullanıcı varsa
      else { 
        setIsLoading(false);

        enqueueSnackbar(data.message, { 
          variant: 'info',
          persist: false
        });
      }
    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });


    e.preventDefault();

  }

  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#40ab9a" />
        </View>
    );
  }

  else if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
          </View>
      );
  }

  else {
    return (
      <BasicLayout image={bgImage}>
        <Card className="md-signin-width">
          <MDBox
            variant="gradient"
            // bgColor="info"
            className="auth-bg"
            borderRadius="lg"
            // coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center">
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Aramıza katıl
            </MDTypography>
  
            <FormControl>
              <RadioGroup
                className='user-type-radio'
                aria-labelledby="user-type-radio"
                name="controlled-user-type-radio"
                value={userType}
                onChange={handleUserTypeChange}>
                <FormControlLabel value="isveren" control={<Radio disabled />} label="İşveren"  />
                <FormControlLabel value="aday" control={<Radio />} label="Bireysel" />
              </RadioGroup>
            </FormControl>
          </MDBox>
  
          <MDBox pt={4} pb={3} px={3}>
  
          {userType == "aday" &&
            
            <MDBox component="form" role="form" onSubmit={handleAdaySignUp}>
              <MDBox mb={2}>
                <MDInput type="text" label="İsim & Soyisim" variant="standard" fullWidth id="adayNameSurname" required/>
              </MDBox>
  
              <MDBox mb={2}>
                <MDInput type="email" label="Email" variant="standard" fullWidth id="adayEmail" required onChange={(e) => validateEmail(e)}/>
                <MDTypography className="signUpErrorMsg" variant="h6" color="error">{emailError}</MDTypography>
              </MDBox>
  
              <MDBox mb={2}>
                <MDInput type="password" label="Şifre" variant="standard" fullWidth id="adayPassword" required onChange={(e) => validatePass(e)}/>
                <MDTypography className="signUpErrorMsg" variant="h6" color="error">{passError}</MDTypography>
              </MDBox>
  
              <MDBox mb={2}>
                <MDInput type="text" label="Meslek" variant="standard" fullWidth id="adayJob" required/>
              </MDBox>
  
              <MDBox display="flex" alignItems="center" ml={-1} mt={1}>
                  <Checkbox required id="adayTerms"/>
              
                  <MDTypography
                    component="a"
                    sx={{ ml: -1, cursor: "pointer", userSelect: "none" }}
                    href="/terms/aday-sign-up"
                    variant="button"
                    fontWeight="bold"
                    className="terms-btn"
                    color="dark"
                    textGradient>
                    Açık Rıza Metnini ve Aydınlatma Metnini kabul Ediyorum.&nbsp;
                  </MDTypography> 
  
              </MDBox>
  
              <MDBox mt={3} mb={1}>
                <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                  Kayıt Ol
                </MDButton>
              </MDBox>
  
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Zaten hesabınız var mı?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="dark"
                    fontWeight="medium"
                    textGradient>
                    Giriş Yap
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
        
          }
  
          </MDBox>
        </Card>
      </BasicLayout>
    );

  }

}

export default SignUp;
