import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TextareaAutosize } from '@mui/base';
import { styled } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { enqueueSnackbar } from 'notistack';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

const Textarea = styled(TextareaAutosize)(({ theme }) => 
 `box-sizing: border-box;
  width: 320px;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #495057;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);

  &:focus {
    border-color: #46b5a7;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }`,
);

function TestEkle() {

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [testTime, setTestTime] = useState("40");

  const uid = window.sessionStorage.getItem("uid");

  const handleTestTime = (e) => {
    setTestTime(e.target.value);
  }

  // QUESTIONS SECTION

  const [questions, setQuestions] = useState([]);

  const handleAddQuestion = (type) => {
    setQuestions([...questions, { type, question: '', answer: '', options: [] }]);
  };

  const handleQuestionChange = (index, key, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][key] = value;
    setQuestions(updatedQuestions);
  };

  const handleAnswerChange = (index, key, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][key] = value;
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (index, optionIndex, option) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options[optionIndex] = option;
    setQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  // QUESTIONS SECTION END

  const handleTestEkle = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const list_obj = {
      "uid": uid,
      "questions": questions,
      "testTitle": e.target.testTitle.value,
      "testTime": Number(testTime)*60
    }

    const atoken = window.sessionStorage.getItem("access_token");

    fetch(process.env.REACT_APP_REQ_IP + '/add-test', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      setIsLoading(false);
      
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'warning',
          persist: false
        });
      }
      else {
        enqueueSnackbar(data.message, { 
          variant: 'success',
          persist: false
        });
      }
    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });

  }

  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#40ab9a" />
        </View>
    );
  }

  else if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
          </View>
      );
  }

  else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
              <MDBox component="form" role="form" onSubmit={handleTestEkle}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  className="ilanFormHeader"
                  coloredShadow="warning">
                  <MDTypography variant="h6" color="white">
                    Aday Testi Ekleme
                  </MDTypography>
  
                  <MDButton variant="gradient" color="dark" type="submit">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Kaydet
                  </MDButton>
                </MDBox>

                <div className='form-container'>
                  <MDBox className="ilanFormInputBox">
                    <InputLabel className="ilanFormInputLabel" htmlFor="bolum">Test Başlığı <sup>*</sup></InputLabel>
                    <MDInput className="ilanFormInput" type="text" id="testTitle" variant="outlined" required/>
                  </MDBox>

                  <MDBox className="ilanFormInputBox">
                    <InputLabel className="ilanFormInputLabel" htmlFor="testTime">Test Süresini Seçiniz <sup>*</sup></InputLabel>
                    <Select
                      id="testTime"
                      value={testTime}
                      className="ilanFormSelectFull"
                      onChange={handleTestTime} required>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={40}>40</MenuItem>
                      <MenuItem value={60}>60</MenuItem>
                    </Select>
                  </MDBox>
                </div>

                <div className='form-container'>
                  <MDBox className="testFormInputBox">

                    <InputLabel className="ilanFormInputLabel queInputLabel" >Test Sorularını Giriniz.</InputLabel>

                    <MDButton type="button" variant="gradient" color="secondary" onClick={() => handleAddQuestion('text')}>
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Yeni soru ekle
                    </MDButton>

                    {questions.map((question, index) => (
                      <MDBox key={index}>
                        <MDBox className="ilanFormQueBox">
                          <div style={{width:"50%"}}>
                          <InputLabel className="ilanFormInputLabel" htmlFor={"queType" + String(index)}>Soru tipi</InputLabel>
                          <Select
                            id={"queType" + String(index)}
                            value="multipleChoice"
                            className="ilanFormSelect">
                            <MenuItem value="multipleChoice">Çoktan Seçmeli</MenuItem>
                          </Select>
                          </div>

                          <MDButton className="queInputDelete que-delete-btn" type="button" variant="gradient" color="secondary" onClick={() => handleRemoveQuestion(index)}>
                              <Icon sx={{ fontWeight: "bold" }}>delete_outline</Icon>
                              &nbsp;Sil
                          </MDButton> 

                        </MDBox>

                        <MDBox>
                            <MDBox className="ilanFormQueBoxCoktan" key="6">
                              <MDBox className="testQueAnswerInput">
                                <InputLabel className="ilanFormInputLabel" htmlFor="queAnswer">Cevap Şıkkı <sup>*</sup></InputLabel>
                                <Select
                                  id="queAnswer"
                                  value={question.answer}
                                  className="ilanFormSelectFull"
                                  onChange={(e) => handleAnswerChange(index, 'answer', e.target.value)} required>
                                  <MenuItem value={0}>Seçenek 1</MenuItem>
                                  <MenuItem value={1}>Seçenek 2</MenuItem>
                                  <MenuItem value={2}>Seçenek 3</MenuItem>
                                  <MenuItem value={3}>Seçenek 4</MenuItem>
                                  <MenuItem value={4}>Seçenek 5</MenuItem>
                                </Select>
                              </MDBox>
                            </MDBox>

                            <Textarea className="ilanFormInput" aria-label="Soruyu yazınız.." minRows={3} placeholder="Soruyu yazınız.." value={question.question} onChange={(e) => handleQuestionChange(index, 'question', e.target.value)} variant="outlined" required />

                            <MDBox className="ilanFormQueBoxCoktan" key="1">
                              <MDInput className="ilanFormInput" type="text"
                              placeholder={`Seçenek 1`} value={questions[index].options[0]} 
                              onChange={(e) => handleAddOption(index, 0, e.target.value)} variant="outlined" required/>
                            </MDBox>


                            <MDBox className="ilanFormQueBoxCoktan" key="2">
                              <MDInput className="ilanFormInput" type="text"
                              placeholder={`Seçenek 2`} value={questions[index].options[1]} 
                              onChange={(e) => handleAddOption(index, 1, e.target.value)} variant="outlined" required/>
                            </MDBox>


                            <MDBox className="ilanFormQueBoxCoktan" key="3">
                              <MDInput className="ilanFormInput" type="text"
                              placeholder={`Seçenek 3`} value={questions[index].options[2]} 
                              onChange={(e) => handleAddOption(index, 2, e.target.value)} variant="outlined"/>
                            </MDBox>


                            <MDBox className="ilanFormQueBoxCoktan" key="4">
                              <MDInput className="ilanFormInput" type="text"
                              placeholder={`Seçenek 4`} value={questions[index].options[3]} 
                              onChange={(e) => handleAddOption(index, 3, e.target.value)} variant="outlined"/>
                            </MDBox>


                            <MDBox className="ilanFormQueBoxCoktan" key="5">
                              <MDInput className="ilanFormInput" type="text"
                              placeholder={`Seçenek 5`} value={questions[index].options[4]} 
                              onChange={(e) => handleAddOption(index, 4, e.target.value)} variant="outlined"/>
                            </MDBox>

                          </MDBox>
                      </MDBox>
                    ))}
                  </MDBox>
                </div>
  
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );

  }

}

export default TestEkle;
