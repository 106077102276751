import { useState, useEffect } from "react";

import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { SnackbarProvider } from 'notistack';

import Sidenav from "examples/Sidenav";

import theme from "assets/theme";

import { handleLogout } from "components/Logout"

import routes from "routes";
import routesAday from "routesAday";
import routesIsveren from "routesIsveren";
import routesAdmin from "routesAdmin";

import Missing from "pages/Missing";

import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import bgImageIsveren from "assets/images/bg_03.png";
import bgImageAday from "assets/images/bg_03.png";
import bgImageAdmin from "assets/images/bg_03.png";

import MDBox from "components/MDBox";

// // Images
import brandWhite from "assets/images/logo-ct.png";
// import brandDark from "assets/images/logo-ct-dark.png";

export default function App() {

  const userType = window.sessionStorage.getItem("userType");
  const uid = window.sessionStorage.getItem("uid");

  let navigate = useNavigate();

  useEffect(() => {
    if(uid === null) {
      handleLogout();
      if(window.location.pathname === "/authentication/admin-sign-in") {
        navigate("/authentication/admin-sign-in", { replace: true });
      }
      else {
        navigate("/authentication/sign-in", { replace: true });
      }
    }

  }, [uid]);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    // direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });


  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {userType == "isveren" &&
          <>
            <MDBox
                position="fixed"
                width="100%"
                minHeight="100vh"
                className="dash-bg-opacity"
                sx={{
                  backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                  bgImageIsveren &&
                    `url(${bgImageIsveren})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
            />
            <Sidenav
              color={sidenavColor}
              // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandWhite : brandDark}
              brand={brandWhite}
              routes={routesIsveren}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
          }

          {userType == "aday" &&
          <>
            <MDBox
              position="fixed"
              width="100%"
              minHeight="100vh"
              className="dash-bg-opacity"
              sx={{
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                bgImageAday &&
                  `url(${bgImageAday})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
            <Sidenav
                color={sidenavColor}
                brand={brandWhite}
                // brandName="Smart Match IK"
                routes={routesAday}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            />
          </>
          }

          {userType == "admin" &&
          <>
            <MDBox
              position="fixed"
              width="100%"
              minHeight="100vh"
              className="dash-bg-opacity"
              sx={{
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                bgImageAdmin &&
                  `url(${bgImageAdmin})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
              }}
            />
            <Sidenav
              color={sidenavColor}
              // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brand={brandWhite}
              routes={routesAdmin}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          
          </>
          }
        </>
      )}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" key="smart-missing" element={<Missing />} />
      </Routes>
    </ThemeProvider>
  );
}
