import { useState, useEffect } from "react";
import { View, ActivityIndicator } from 'react-native';

import Grid from "@mui/material/Grid";

import { enqueueSnackbar } from 'notistack';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import coinIcon from "assets/images/icons/smart_coin.png";

function DashboardAday() {

  const [userData, setUserData] = useState();

  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  useEffect(() => {

    var list_obj = {
      'uid': uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/get-user-specific', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
      }
      else {
        setUserData(data.info)
      }
    })
    .catch(err => {
        console.log(err);
    });

  }, [uid]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {
            userData &&
            <>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="note"
                    title="Toplam Başvuru Sayınız"
                    count={userData?.topBasv}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Şimdi güncellendi",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color= "primary"
                    icon="person_add"
                    title="Toplam Önerileriniz"
                    count={userData?.topOner}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Şimdi güncellendi",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="warning"
                    className="external-icon"
                    icon={<img className="smart-alt" alt="smart_coin" src={coinIcon} />}
                    title="SmartCoin"
                    count={userData?.smartcoin}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Şimdi güncellendi",
                    }}
                  />
                </MDBox>
              </Grid>
            </>
          }

          {!userData &&
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#40ab9a" />
            </View>
          }
          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DashboardAday;
