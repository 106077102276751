const gelisimselData = [
  "Problem çözme becerileri",
  "Eleştirel düşünme",
  "İletişim becerileri",
  "Ekip çalışması",
  "Zaman yönetimi",
  "Karar verme & İnsiyatif alma",
  "Öğrenmeye açıklık",
  "Esneklik",
  "İnovasyon",
  "Yaratıcılık",
  "Liderlik",
  "Empati",
  "Kendi kendine öğrenme",
  "Bağlamsal düşünme",
  "Müşteri odaklılık",
  "Analitik düşünme",
  "Değişime uyum sağlama",
  "Stratejik düşünme",
  "Duygusal zeka",
  "Stres yönetimi",
  "Etkileme ve ikna kabiliyeti"
];

export default gelisimselData;