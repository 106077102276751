import React, { useState, useEffect } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { enqueueSnackbar } from 'notistack';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useLocation } from 'react-router-dom';

function IlanaBasvur() {

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [questions, setQuestions] = useState(null);
  const [queExist, setQueExist] = useState(false);

  const [uploadFile, setUploadFile] = useState();

  const [answers, setAnswers] = useState([]);

  const {state} = useLocation();
  const { ilanid, pozisyonunvani, sirketismi } = state; 

  useEffect(() => {
    if(!ilanid || !pozisyonunvani || !sirketismi) {
      ilanid = window.sessionStorage.getItem("ilanid");
      pozisyonunvani = window.sessionStorage.getItem("pozisyonunvani");
      sirketismi = window.sessionStorage.getItem("sirketismi");
    }
  }, [ilanid, pozisyonunvani, sirketismi])


  const uid = window.sessionStorage.getItem("uid");
  const atoken = window.sessionStorage.getItem("access_token");

  const isObjectInArrays = (queID) => {

    var breakException = {};

    try {
      answers.forEach((element, index) => {
        if(element["queID"] == queID) {
          breakException = {result: true, tmp_index: index}
          throw breakException;
        }
      });
    }
    catch (e) {
      if (e !== breakException) throw e;
    }

    if(breakException?.result == true) {
      return breakException
    }
    else {
      return {result: false}
    }
  };


  const handleAnswersChanged = (answer, queID) => {
    const tmp_answer = answer.target.value;
    const tmp_obj = {uid, queID, ilanid, tmp_answer}

    var tmp_result = isObjectInArrays(queID)

    if (!tmp_result['result']) {
      setAnswers([...answers, tmp_obj])
    }
    else {
      const tmp_new_answers = answers.map((value, index) => {
        if(index === tmp_result['tmp_index']) {
          return value = tmp_obj
        }
        else {
          return value;
        }
      })

      setAnswers(tmp_new_answers);
    }

  }


  useEffect(() => {

    setIsLoading(true);

    if(!ilanid) {
      var list_obj2 = {
        'ilanID': window.sessionStorage.getItem("ilanid")
      }
    }
    else {
      var list_obj2 = {
        'ilanID': ilanid
      }
    }

    fetch(process.env.REACT_APP_REQ_IP + '/get-spec-que', { method: 'POST', mode: 'cors' ,headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj2)}).then(res => res.json()).then(data => {
      
      setIsLoading(false);
      
      if(data.result === false) {
        setQueExist(false)
      }
      else {
        setQueExist(true)
        setQuestions(data.specQue)
      }
    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });

  }, [uid, ilanid]);


  const handleFile = (event) => {
    const file_size = event.target?.files[0]?.size;

    if(file_size) {
        const file_size_mb = (file_size / (1024*1024));
        if(file_size_mb > 1000) {
            enqueueSnackbar("Dosyanızın boyutu 1GB altında olmalıdır.", { 
                variant: 'error',
                persist: false
            });
        }
        else {
            enqueueSnackbar("Dosyanız başarı ile yüklendi.", { 
                variant: 'info',
                persist: false
            });

            setUploadFile(event.target.files[0]);
        }
    }
  };


  const handleBasvur = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const formData = new FormData()
    formData.append('files', uploadFile)
    formData.append('onYazi', e.target.onyazi.value)
    formData.append('ilanID', ilanid)
    formData.append('uid', uid)

    answers.forEach((tmp_answerS, index) => {
      formData.append('ans' + String(index), tmp_answerS.uid + '=§*§=' + tmp_answerS.queID + '=§*§=' + tmp_answerS.ilanid + '=§*§=' + tmp_answerS.tmp_answer)
    })

    fetch(process.env.REACT_APP_REQ_IP + '/ilana-basvur', { method: 'POST', mode: 'cors', headers: {
      'Authorization': `Bearer ${atoken}`
    }, body: formData}).then(res => res.json()).then(data => {
      
      setIsLoading(false);
      
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });
      }
      else {
        enqueueSnackbar(data.message, { 
          variant: 'success',
          persist: false
        });
      }
    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });

  }
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>

            <MDBox component="form" role="form" onSubmit={handleBasvur}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  className="ilanFormHeader"
                  coloredShadow="info">
                  <MDTypography variant="h6" color="white">
                    İlana Başvur : {pozisyonunvani} - {sirketismi} 
                  </MDTypography>

                  <div>
  
                    <MDButton variant="gradient" color="dark" type="submit">
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Başvur
                    </MDButton>

                  </div>
                </MDBox>

            {error && 
              <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={{ fontSize: 18, color:"white"}}>Hata: {error}</Text>
              </View>
            }
            {isLoading && 
              <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#40ab9a" />
              </View>
            }
            {!isLoading && !error &&
            <>
                <MDBox className="ilanFormInputBox">
                  <MDTypography variant="h6" className="ilanFormInputLabel" htmlFor="fileUploadInp">Lütfen CV yükleyiniz. Desteklenen CV uzantıları : .pdf, .docx <sup>*</sup></MDTypography>
                  <input
                    onChange={handleFile}
                    id="fileUploadInp"
                    accept="application/pdf"
                    className="ilanFormInput file-upload-input"
                    type="file"
                    name="file"
                    required
                  />

                  <MDTypography
                      component="a"
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      href="/terms/cv-paylasimi"
                      variant="button"
                      fontWeight="bold"
                      className="terms-cv-btn"
                      color="dark"
                      textGradient>
                      <Icon sx={{ fontWeight: "bold" }}>link</Icon>&nbsp;
                      Cv yükleyerek, cv paylaşım metnini kabul etmiş olursunuz.
                  </MDTypography> 
                </MDBox>

              <div className='form-container'>
                <MDBox className="ilanFormInputBox">
                  <MDTypography variant="h6" className="ilanFormInputLabel" htmlFor="onyazi">Ön Yazı <sup>*</sup></MDTypography>
                  <MDInput className="ilanFormInput" type="text" id="onyazi" rows={15} variant="outlined" multiline required/>
                </MDBox>
              </div>

      
                {queExist &&

                <div className='form-container'>

                <MDBox className="ilanFormInputBox">
                  <MDTypography variant='h6' className="ilanFormInputLabel queInputLabel">İşveren Soruları</MDTypography>

                    {questions.map((question, index) => (
                        <MDBox key={index}>
                          
                          {question[2] === 'text' ? (
                            <MDBox className="queInputLabel">
                              <InputLabel className="ilanFormInputLabel queLabel" htmlFor={index}>Soru {index + 1} : {question[3]} <sup>*</sup></InputLabel>
                              <MDInput className="ilanFormInput" type="text" id={String(index)} variant="outlined" onChange={(e) => handleAnswersChanged(e, question[8])}/>
                            </MDBox>

                          ) : (
                            <MDBox className="queInputLabel">
                              <InputLabel className="ilanFormInputLabel queLabel">Soru {index + 1} : {question[3]} <sup>*</sup></InputLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group" onChange={(e) => handleAnswersChanged(e, question[8])}>
                                  <FormControlLabel value={question[4]} control={<Radio />} label={"A) " + question[4]} />
                                  <FormControlLabel value={question[5]} control={<Radio />} label={"B) " + question[5]} />

                                  {question[6] &&
                                    <FormControlLabel value={question[6]} control={<Radio />} label={"C) " + question[6]} />
                                  }

                                  {question[7] &&
                                    <FormControlLabel value={question[7]} control={<Radio />} label={"D) " + question[7]} />
                                  }
                                </RadioGroup>
                            </MDBox>
                          )}
                        </MDBox>
                      ))} 
                    </MDBox>
                  </div>
                }
              </>
            }

              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default IlanaBasvur;
