import { useState, useEffect } from "react";

import { enqueueSnackbar } from 'notistack';
import { saveAs } from 'file-saver';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import PropTypes from 'prop-types';
import Icon from "@mui/material/Icon";

export default function basariliEslesmelerTableData() {

  const [basarililar, setBasarililar] = useState();
  const [error, setError] = useState(null);

  const uid = window.sessionStorage.getItem("uid");

  const saveManual = (ilanid, adayUid) => {
    saveAs(
      process.env.REACT_APP_PYTHON_REDIRECT_IP + "/files/" + ilanid + '/' + adayUid + '.pdf'
    );
  };

  const Pozisyon = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const Status = ({name}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="h6" fontWeight="medium" lineHeight={1}>
        <div className="badge-container" color="warning">
        {name}
        </div>
      </MDTypography>
    </MDBox>
  );

  Pozisyon.propTypes = {
    name: PropTypes.string
  };

  Status.propTypes = {
    name: PropTypes.string
  };

  const atoken = window.sessionStorage.getItem("access_token");

  useEffect(() => {

    var list_obj = {
      'uid': uid
    }

    fetch(process.env.REACT_APP_REQ_IP + '/basarili-eslesmeler', { method: 'POST', mode: 'cors', headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${atoken}`
    }, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      if(data.result === false) {
        enqueueSnackbar(data.message, { 
          variant: 'error',
          persist: false
        });

        setError(data.message);
      }
      else {
        setBasarililar(data.info);
      }
    })
    .catch(err => {
        setError(err);
    });

  }, [uid]);

  if(error) {
    return {columns: [
      { Header: "Aday İsmi", accessor: "adayIsmi", align: "left" },
      { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
      { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", align: "left" },
      { Header: "Personel CV'si", accessor: "adayCV", align: "center" },
    ],
    rows: [{}]
    }
  }
  else {
    return {
      columns: [
        { Header: "Aday İsmi", accessor: "adayIsmi", align: "left" },
        { Header: "Şirket İsmi", accessor: "sirketIsmi", align: "left" },
        { Header: "Pozisyon Ünvanı", accessor: "pozisyonUnvani", align: "left" },
        { Header: "Personel CV'si", accessor: "adayCV", align: "center" },

      ],
      rows: 
          basarililar?.map((eslesmeler) => (
            {
              adayIsmi: <Pozisyon name={eslesmeler[2]} />,
              sirketIsmi: <Pozisyon name={eslesmeler[0]} />,
              pozisyonUnvani: <Pozisyon name={eslesmeler[1]} />,
              adayCV: (
                <MDButton className="queInputDelete" type="button" variant="gradient" color="dark" onClick={() => saveManual(eslesmeler[4], eslesmeler[3])}>
                  <Icon sx={{fontWeight: "bold"}}>download</Icon>
                  &nbsp;CV indir
                </MDButton> 
              )
            }
          ))
    };

  }
}